import React from 'react';

import { Roles } from '../../types/roles.enum';
import { Layout } from 'antd';
import { Outlet } from 'react-router';

import Sidebar from '../../components/Layout/Siderbar';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';

const { Content } = Layout

import './styles.css'

interface ApplicationProps {
  role: Roles;
  logout: () => void;
}

const Application = ({ logout }: ApplicationProps) => {
  return (
    <Layout className='app'>
      <Sidebar logout={logout}/>
      <Layout className='main'>
        <Header />
        <Content className='app-content'>
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Application;
