import { Action, Dispatch } from 'redux';
import { taxConstants } from '../../constants/tax.constants';
import { taxService } from '../../services/tax.service';
import { TaxGroupInterface } from '../../types/tax_group.type';

const loadTaxes = (company_id: number) => {
  const success = (taxGroup: TaxGroupInterface) => {
    return { type: taxConstants.LOAD_TAXES, taxGroup };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const taxGroup = await taxService.getSelfTaxGroup(company_id);
      const data = taxGroup as TaxGroupInterface;
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

export const taxActions = {
  loadTaxes,
};
