import React from 'react';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';

import moment from 'moment';

import './styles.css'

interface InputDateInterface {
    disabledDate?: boolean;
    validate?: string;
    errorMessage?: string;
    disabled?: boolean;
    onChange?: ((value: moment.Moment | null, dateString: string) => void) | undefined
}

const InputDate = ({
    disabledDate,
    validate,
    errorMessage,
    disabled,
    onChange
}:InputDateInterface) => {

    const handleDisabledDate: RangePickerProps['disabledDate'] = current => { 
        const currentDate = new Date()
        const currentHour = currentDate.getHours()
        
        if (currentHour >= 18) return current < moment().endOf('day')
        return current < moment().subtract(1, 'days').endOf('day')
    };

    return (
        <div>
            <DatePicker 
                disabled={disabled}
                className='container-input input-primary input'
                size='large'
                format="DD/MM/YYYY"
                disabledDate={disabledDate ? handleDisabledDate : undefined}
                onChange={onChange}
            />
            {(validate==='error' || validate==='success') &&
                <div className={`helper-text ${validate}`}>
                    <span style={validate === "error" ? {color: "#FF0000"} : {color: "#00C108"} }>{errorMessage}</span>
                </div>
            }
        </div>
    )
}

export default InputDate;