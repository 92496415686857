import { useEffect, useState } from "react";
import { RepresentativesInterface } from "../../types/user.type";
import { CompaniesService } from "../../services/company.service";
import Logo from '../../static/tamborete-pay-modal.png';
import { Button, message, Modal, Select } from "antd";

interface RepresentativeModalProps{
    open: boolean;
    company_id: number;
    onClose: () => void;
}

const RepresentativeModal = ({
    open,
    company_id,
    onClose,
}: RepresentativeModalProps) => {
    const [loadingActive, setLoadingActive] = useState<boolean>(false)
    const [representative, setRepresentative] = useState<number>()
    const [representatives, setRepresentatives] = useState<RepresentativesInterface[]>([])
    useEffect(() => {
        const getRepresentatives = async () => {
            const response = await CompaniesService.listRepresentatives()
            if (response) {
                setRepresentatives(response)
            }
        }
        getRepresentatives()
    }, []);

    const handleSetRepresentative = async () => {
        setLoadingActive(true)
        try {
            if (!representative) {
                setLoadingActive(false)
                message.error(`Selecione um representante`);
                return
            }
            const response = await CompaniesService.setCompanyRepresentative(company_id, representative);
            if(response) {
                message.success(`Representante alterado com sucesso`);
                window.location.reload()
            } else {
                setLoadingActive(false)
                message.error(`Falha ao alterar representante`);
            }
        } catch (error) {
            setLoadingActive(false)
            message.error(`Falha ao alterar representante`);
        }
    }
    const ModalTitle = () => {
        return (
            <div className='delay-modal-title-container'>
                <img src={Logo} alt="" />
            </div>
        )
      }
    
      const ModalFooter = () => {
        return (
            <div className='delay-modal-footer-content'>
                <Button className='delay-modal-button-reprove' onClick={onClose}>Cancelar</Button>
                <Button className='delay-modal-button-approve' loading={loadingActive} onClick={handleSetRepresentative}>Alterar</Button>
            </div>
        )
      }

    return (
        <Modal
            className="delay-modal"
            title={<ModalTitle />}
            footer={<ModalFooter />}
            open={open}
            onCancel={onClose}
            destroyOnClose
        >
            <Select 
                className="custom-select"
                placeholder="Seleciona o representante"
                onChange={(value) => setRepresentative(value)}
                value={representative}
            >
                {representatives?.map((option) =>
                    <Select.Option 
                        key={option.id} 
                        value={option.id}
                    >
                        {`${option.id} - ${option.name}`}
                    </Select.Option>
                )}
            </Select>
        </Modal>
    )
}

export default RepresentativeModal;
