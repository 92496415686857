import { AnyAction } from 'redux';
import { withdrawConstants } from '../constants/withdraw.constants';

const withdraw = (state = { withdrawals: [], active_withdrawals: [], dirt: false }, action: AnyAction) => {
  switch (action.type) {
    case withdrawConstants.LOAD_WITHDRAWALS:
      return { ...state, withdrawals: action.withdrawals, dirt: false };
    case withdrawConstants.LOAD_ACTIVE_WITHDRAWALS:
      return { ...state, active_withdrawals: action.withdrawals, dirt: false };
    case withdrawConstants.CONCLUDE_WITHDRAW:
      return { ...state, dirt: true };
    case withdrawConstants.REFUSE_WITHDRAW:
    case withdrawConstants.CREATE_WITHDRAW:
      return { ...state, dirt: true };
    default:
      return state;
  }
};

export default withdraw;
