import API from '../factory/api';
import { BankAccountInterface } from '../types/bank_account.type';
import { CardMachineInterface, CardMachineModelInterface } from '../types/card_machine.type';
import { ClientInterface } from '../types/client.type';
import { SummaryInterface } from '../types/user.type';

const getClients = async (): Promise<ClientInterface[]> => {
  try {
    const { data } = await API().post('/user/list', {});
    const clients = data as ClientInterface[];
    return clients;
  } catch (error) {
    throw error;
  }
};

const createClient = async (client: ClientInterface) => {
  await API().post('/user/insert', client);
};

const getClient = async (user_id: number): Promise<ClientInterface> => {
  try {
    const { data } = await API().post(`/user/${user_id}`, {});
    return data as ClientInterface;
  } catch (error) {
    throw error;
  }
};



/* card machine */

const getCardMachinesByUser = async (user_id: number): Promise<CardMachineInterface[]> => {
  try {
    const { data } = await API().post(`company/${user_id}/card_machines`, {});
    return data as CardMachineInterface[];
  } catch (error) {
    throw error;
  }
};

const createCardMachines = async (user_id: number, card_machine: CardMachineInterface) => {
  try {
    await API().post(`company/${user_id}/card_machine/insert`, card_machine);
  } catch (error) {
    throw error;
  }
};

const deleteCardMachines = async (user_id: number, card_machine_id: number) => {
  try {
    await API().post(`company/${user_id}/card_machine/delete/${card_machine_id}`, {});
  } catch (error) {
    throw error;
  }
};







const getCardMachines = async (): Promise<CardMachineInterface[]> => {
  try {
    const { data } = await API().post(`/cardmachine/list`, {});
    return data as CardMachineInterface[];
  } catch (error) {
    throw error;
  }
};



const createCardMachineModel = async (card_machine_model: CardMachineModelInterface) => {
  try {
    await API().post(`/cardmachinemodel/insert`, card_machine_model);
  } catch (error) {
    throw error;
  }
};



const deleteCardMachineModel = async (card_machine_model_id: number) => {
  try {
    await API().post(`/cardmachinemodel/delete/${card_machine_model_id}`, {});
  } catch (error) {
    throw error;
  }
};

const getCardMachineModels = async (): Promise<CardMachineModelInterface[]> => {
  try {
    const { data } = await API().post(`/cardmachinemodel/list`, {});
    return data as CardMachineModelInterface[];
  } catch (error) {
    throw error;
  }
};

const getBankAccounts = async (user_id?: number): Promise<BankAccountInterface[]> => {
  try {
    const uri = user_id ? `/bankinformation/list/${user_id}` : `/bankinformation/list/self`;
    const { data } = await API().post(uri, {});
    return data as BankAccountInterface[];
  } catch (error) {
    throw error;
  }
};

const createBankAccount = async (bank_account: BankAccountInterface): Promise<void> => {
  try {
    await API().post(
      `/bankinformation/insert${bank_account.user_id != undefined ? '/' + bank_account.user_id : ''}`,
      bank_account,
    );
  } catch (error) {
    throw error;
  }
};

const deleteBankAccount = async (bank_account_id: number): Promise<void> => {
  try {
    await API().post(`/bankinformation/delete/${bank_account_id}`, {});
  } catch (error) {
    throw error;
  }
};

const getSummaryAccount = async ( user_id: number ): Promise<SummaryInterface> => {
  try {
    const jsonData = {
      user_id: user_id,
      start: "2020-01-01",
      end: "2030-12-30"
    }
    const { data } = await API().post(`/balancehistory/list_by_date`, jsonData);
    return data as SummaryInterface;
  } catch (error) {
    throw error;
  }
};

export const clientsService = {
  getClients,
  getClient,
  getCardMachinesByUser,
  getCardMachines,
  getBankAccounts,
  getCardMachineModels,
  createClient,
  createCardMachines,
  createCardMachineModel,
  createBankAccount,
  deleteCardMachines,
  deleteCardMachineModel,
  deleteBankAccount,
  getSummaryAccount,
};
