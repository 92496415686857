import React from "react";
import ReactInputVerificationCode from 'react-input-verification-code';

import './styles.css'

interface InputTokenInterface {
    length: number;
    value: string;
    validate?: string;
    errorMessage?: string;
    onChange: (data: string) => void;
}

const InputToken = ({
    length,
    value,
    validate,
    errorMessage,
    onChange
}:InputTokenInterface) => {
    return (
        <>
            <div className="token">
                <ReactInputVerificationCode 
                    placeholder=""
                    value={value}
                    length={length}
                    onChange={onChange}
                />
            </div>
            {validate==='error' &&
                <div className={`helper-text ${validate}`}>
                    <span style={validate === "error" ? {color: "#FF0000"} : {color: "#00C108"} }>{errorMessage}</span>
                </div>
            }
        </>
    )
}

export default InputToken;