import { useState } from "react";
import { CopyOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'

interface LinkPaymentsModalProps {
  open: boolean;
  link:string;
  onClose: () => void;
}

const LinkPaymentsModal = ({
  open,
  link,
  onClose
}: LinkPaymentsModalProps) => {

  const [loadingActive, setLoadingActive] = useState<boolean>(false)
  const [forms, setForms] = useState<{coils_count: string;}>({coils_count: ""})

  const handleApproveActionPayments = async () => {
    setLoadingActive(true)
    console.log(forms)
  }
  


  const ModalTitle = () => {
    return (
        <div className='link-paiments-modal-title-container'>
            <img src={Logo} alt="" />
        </div>
    )
  }

  const ModalFooter = () => {
    return (
        <div className='link-paiments-modal-footer-content'>
        </div>
    )
  }
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      console.log('Texto copiado para a área de transferência');
    } catch (err) {
      console.log('Falha ao copiar o texto', err);
    }
  };
  return (
    <Modal
      className="link-paiments-modal"
      title={<ModalTitle />}
      footer={<ModalFooter />}
      onCancel={onClose}
      width={400}
      open={open}
    >
      <span className="link-paiments-modal-body-title">Link gerado</span>
      <div>
        
      <a href={link} target="_blank" rel="noopener noreferrer">{link}</a> 
      <Button className= "btn-copy"icon={<CopyOutlined  />} onClick={copyToClipboard}/>
      </div>
    </Modal>
  )
}

export default LinkPaymentsModal;