import React from 'react';

import { Select } from 'antd';

const { Option } = Select;

import './styles.css'

interface OptionsProps {
    label: string;
    value: string;
}

interface InputSelectProps {
    defaultValue?:  string;
    style?: React.CSSProperties,
    options: OptionsProps[];
    validate?: string;
    errorMessage?: string;
    value?: string;
    placeholder?: string;
    kind?: "primary" | "secondary";
    onChange?: (data:string) => void;
}

const InputSelect = ({
    defaultValue,
    options,
    style,
    validate,
    errorMessage,
    value,
    placeholder,
    kind,
    onChange
}: InputSelectProps) => {

    const children = options.map(item => <Option key={item.value}>{item.label}</Option>)
    
    return (
        <div>
            <Select 
                placeholder={placeholder ? placeholder : "Filtrar por..."}
                className={`input-select ${'input-select-'+kind}`}
                defaultValue={defaultValue} 
                style={style}
                value={value}
                onChange={onChange}
            >
                {children}
            </Select>
            {(validate==='error' || validate==='success') &&
                <div className={`helper-text ${validate}`}>
                    <span style={validate === "error" ? {color: "#FF0000"} : {color: "#00C108"} }>{errorMessage}</span>
                </div>
            }
        </div>
    )
}

export default InputSelect;