import axios, { AxiosInstance } from 'axios';
import config from '../config';

const VIACEP = ():AxiosInstance => {
    const instance = axios.create({
        baseURL: config.viaCep,
        timeout: 10000,
    });

    return instance
}
export default VIACEP;