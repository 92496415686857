import React from "react";
import Table, { ColumnsType } from 'antd/lib/table';
import { Col, Row, Avatar, Button, message } from "antd";
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import {ReactComponent as ProfileIcon} from '../../static/icon/profile-green.svg';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as PermissionIcon} from '../../static/icon/permission-green.svg';

import './styles.css'
import { UserInterface } from "../../types/user.type";
import { companiesActions } from "../../actions/companies";
import { connect, ConnectedProps } from "react-redux";

interface AppState {
    user: {
        info: UserInterface;
    }
}

interface UsersTableInterface {
    users?: UserInterface[],
    company_id?: number;
}

const mapState = (state: AppState) => ({ user: state.user.info });

const connectedApp = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connectedApp>;

const UsersTable = ({
    user,
    users,
    company_id
}: UsersTableInterface & PropsFromRedux) => {

    const { innerWidth: width } = window;
    const navigate = useNavigate()
    const goTo = (path:string) => {
        navigate(path)
    }

    const handleUsers = () => {
        return users?.map(item => {
            const permissions = item.permissions ? Object.values(item.permissions).filter(item => item).length : 0
            const limits = item.limits ? item.limits.length : 0
    
            item.permissionsCount = permissions + limits
    
            return item
        })
    }

    const handleDisassiociateUser = async (user_id:number) => {
        if (user.id !== user_id) {
            const user = {
                user_id: user_id
            }
            
            if (company_id) {
                const response = await companiesActions.disassociateUser(company_id, user)
                if (response.success) createAlert("Usuário desassociado com sucesso.", true)
                else createAlert("Falha ao desassociar usuário.", true)
            }
        } else {
            message.error("Você não pode desassociar a sua conta.")
        }
    }

    const createAlert = (messageAlert: string, success: boolean) => {
        if (success) {
            message.success(messageAlert)
            setTimeout(() => window.location.reload(), 3000)
        }
        else message.error(messageAlert)
    }

    const columns: ColumnsType<UserInterface> = [
        {
            key:"avatar",
            dataIndex: "name",
            width: width <= 414 ? 50 : width <= 533 ? 65 : 85,
            render: (record:string) => {
                return (
                    <Col className='table-cell first-cell' style={{alignItems: "center"}}>
                        <Avatar>{record ? record[0].substring(0, 1).toUpperCase() : "T"}</Avatar>
                    </Col>
                )
            }
        },
        {
            key: "name",
            dataIndex: "name",
            title: "Usuário",
            width: width <= 414 ? 150 : width <= 533 ? 175 : 250,
            render: (record:string) => {
                return (
                    <Col className='table-cell'>
                        <Row align='middle'>
                            <ProfileIcon />
                            <span className='table-title'>Nome</span>
                        </Row>
                        <Row >
                            <span className='table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "email",
            dataIndex: "email",
            title: "E-mail",
            width: width <= 414 ? 200 : width <= 533 ? 225 : 350,
            render: (record:string) => {
                return (
                    <Col className='table-cell'>
                        <Row align='middle'>
                            <CalendarIcon />
                            <span className='table-title'>E-mail</span>
                        </Row>
                        <Row>
                            <span className='table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        }, 
        {
            key: "permissionsCount",
            dataIndex: "permissionsCount",
            title: "Permissões",
            width: width <= 533 ? 150 : 300,
            render: (record) => {
                return (
                    <Col className='table-cell'>
                        <Row align='middle'>
                            <PermissionIcon/>
                            <span className='table-title'>Permissões</span>
                        </Row>
                        <Row>
                            <span className='table-value'>{record}/6</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "actions",
            width: 115,
            dataIndex: "id",
            render: (record:number) => {
                return (
                    <Col className='table-cell last-cell'>
                        <Row>
                            <Button className="table-profile-edit-button" style={{marginRight: 5}} shape="circle" size="large" icon={<EditFilled/>} onClick={() => goTo(`/perfil/adicional/editar?user_id=${record}&company_id=${company_id}`)}/>
                            <Button className="table-profile-edit-button" shape="circle" size="large" icon={<DeleteFilled/>} onClick={() => handleDisassiociateUser(record)}/>
                        </Row>
                    </Col>
                )
            }
        }
    ]

    return (
        <Table
            showHeader={false}
            pagination={false}
            dataSource={handleUsers()}
            columns={columns}
            size="middle"
            className="table"
        />
    )
}

export default connectedApp(UsersTable);