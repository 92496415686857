import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { UserInterface } from '../../types/user.type';

import ClosedEye from '../../static/closed-eye-primary.png'
import OpenedEye from '../../static/opened-eye-primary.png'

import PixReceiptForms from './Forms';

import './styles.css'
import { companiesActions } from '../../actions/companies';

interface RootState {
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const actionCreators = {
    getCompanyDetails: companiesActions.getCompanyDetails
};

const connector = connect(mapState, actionCreators);

type PropsPixRedux = ConnectedProps<typeof connector>;

const PixReceipt = ({ user, getCompanyDetails }: PropsPixRedux) => {

    const [visibleBalance, setVisibleBalance] = useState<boolean>(false)
    const [balance, setBalance] = useState<number>(0)

    useEffect(() => {
        const getData = async () => {
            if (user.companies) {
                const company_id = user.companies[0].id
                const response = await getCompanyDetails(company_id)
                setBalance(response.balance)
            }
        }
        getData()
    }, [user])

    return (
        <div className='pix-receipt-container'>
            <div className='pix-receipt-content-item'>
                <h3 style={{textAlign: "center"}}>PAGAMENTO VIA PIX</h3>
                <div className='pix-receipt-balance'>
                    <span>Saldo em conta</span>
                    <div className='pix-receipt-balance-button-content'>
                        <span>{visibleBalance ? (
                            Number(balance).toLocaleString("pt-br", {style: "currency", currency: "BRL"})
                        ) : (
                            "R$ ****,**"
                        )}</span>
                        <Button 
                            ghost
                            onClick={() => setVisibleBalance(!visibleBalance)}
                            icon={<img alt='' src={visibleBalance ? OpenedEye : ClosedEye}/>}
                            className="pix-receipt-balance-button"
                        />
                    </div>
                </div>
                <PixReceiptForms/>
            </div>
        </div>
    )
}

export default connector(PixReceipt);