import { Button, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AuthenticationFormsInterface } from '..';
import InputText from '../../../components/Inputs/Text';
import { authActions } from '../../../actions/auth';

interface ForgotPasswordState {
  email: string;
}

const mapState = () => ({});

const actionCreators = {
  requestSecurityCode: authActions.requestSecurityCode,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ForgotPasswordProps = PropsFromRedux & {
  requestSecurityCode: (email: string) => void;
  changeForms: (formsSelected: keyof AuthenticationFormsInterface) => void
};

const ForgotPasswordPage = (props: ForgotPasswordProps) => {
  const [forms, setForms] = useState<ForgotPasswordState>({
    email: ``,
  });

  interface formsError {
    email: { validate: string; message: string; }
  }
  const [formsError, setError] = useState<formsError>({
    email: {validate: "", message: ""},
  }) 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForms({
      ...forms,
      [name]: value,
    });
  };

  const onFinish = async () => {
    const { email } = forms;
    if (email) {
      const response = await props.requestSecurityCode(email);

      if (response) {
        setError({
          ...formsError,
          email: {
            validate: "success",
            message: "E-mail enviado com orientações para a recuperação de senha."
          }
        })
      } else {
        setError({
          ...formsError,
          email: {
            validate: "error",
            message: "Tente novamente mais tarde."
          }
        })
      }
    }
  };

  return (
    <>
      <Button 
        className="arrow" 
        type="ghost" 
        icon={<ArrowLeftOutlined style={{fontSize: "26px"}}/>} 
        onClick={() => props.changeForms("login")}
      />
      <Form
        className="form"
        onFinish={onFinish}
        autoComplete="on"
      >
        <h3 className='title'>Esqueceu a senha?</h3>
        <span className='subtitle'>Preencha o forulário abaixo e enviaremos instruções de como resetar sua senha por e-mail:</span>
        <Form.Item
          style={{
            marginTop: 20,
          }} 
          rules={[{ required: true, message: 'Por favor digite um usuário' }]}
        >
          <InputText 
            kind="ghost"
            placeholder="Seu e-mail" 
            name="email" 
            onChange={handleChange}
            validate={formsError.email.validate}
            errorMessage={formsError.email.message}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
          <Button 
            className="submit" 
            type="primary" 
            htmlType="submit"
          >
            Enviar e-mail de recuperação
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default connector(ForgotPasswordPage);
