import React from 'react';

import { Dropdown, Menu, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom'

import '../styles.css'

const CustomDropdown = ({
    user
}: {user:string}) => {

    const navigate = useNavigate()

    type MenuItem = Required<MenuProps>['items'][number];
    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        onClick: () => void
    ):MenuItem => {
        return {
            key,
            label,
            onClick
        }
    }

    const items: MenuItem[] = [
        getItem("Meu dados", "1", () => navigate('/perfil')),
        getItem("Taxas de cartão e Tarifa de conta", "2", () => navigate('/')),
        getItem("Contrato Tamborete Pay", "3", () => navigate('/')),
        getItem("Termo de uso", "4", () => navigate('/')),
        getItem("Política de privacidade", "5", () => navigate('/')),
        getItem("Fale conosco", "6", () => navigate('/')),
    ]

    return (
        <Dropdown overlay={<Menu items={items} />} placement="bottomRight" arrow>
            <Avatar size="large" style={{fontSize: 22, cursor: "pointer"}}>{user ? user[0].substring(0, 1).toUpperCase() : "T"}</Avatar>
        </Dropdown>
    )
};

export default CustomDropdown;