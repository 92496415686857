import { useState } from 'react';
import { Card, Col, Image, Row } from 'antd';
import Logo from '../../static/tamborete.png';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Company from './Company';

import './styles.css';
import TokenPage from './Token';

export interface AuthenticationFormsInterface {
  login: JSX.Element;
  forgotPassword: JSX.Element;
  token: JSX.Element;
  company: JSX.Element;
}

const AuthenticationPage = () => {
  
  const [formsActive, setFormsActive] = useState<keyof AuthenticationFormsInterface>('login')

  const handleSetFormsActive = (formsSelected:keyof AuthenticationFormsInterface) => {
    setFormsActive(formsSelected)
  }

  const forms:AuthenticationFormsInterface = {
    login: <Login changeForms={handleSetFormsActive}/>,
    forgotPassword: <ForgotPassword changeForms={handleSetFormsActive}/>,
    token: <TokenPage changeForms={handleSetFormsActive}/>,
    company: <Company changeForms={handleSetFormsActive}/>
  }

  return (
      <Col xs={24} sm={24} xl={24} className="container">
        <Row align='middle' justify='center' style={{height: "100%"}}>
          <Col xs={22} sm={20} md={16} lg={14} xl={12} style={{height: "100%", display: 'flex', flexDirection: "column", justifyContent: "center"}}>
            <Card className='card'>
              <Row align='middle' justify='space-around' style={{height: "100%"}}>
                <Col xs={22} sm={20} md={18} lg={16} xl={16} xxl={12}>
                  <Row align='middle' justify='center' style={{marginBottom: 25}}>
                    <Image preview={false} src={Logo} width={window.innerWidth <= 414 ? "200px" : "250px"}/>
                  </Row>
                  {forms[formsActive]}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
  );
};

export default AuthenticationPage;