import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AuthenticationFormsInterface } from '..';
import { UserInterface } from '../../../types/user.type';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form} from 'antd';
import { authActions } from '../../../actions/auth';

interface RootState {
  authentication: {
    user: UserInterface;
  };
}

const mapState = (state: RootState) => ({
  user: state.authentication.user
})

const actionCreators = {
  login: authActions.login
}

const connector = connect(mapState, actionCreators)

type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenPageProps = PropsFromRedux & {
  changeForms: (formsSelected: keyof AuthenticationFormsInterface) => void;
}

const Company = (props: TokenPageProps) => {

  const cnpjMask = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
  const [companySelected, setCompany] = useState<number>(-1)

  const onFinish = () => {
    if (companySelected > 0) {
      const localStorage = props.user
      localStorage.companies = props.user.companies?.filter(item => item.id === companySelected)
      props.login(localStorage)
    }
  }

  return (
    <>
      <Button 
        className="arrow"
        style={
          window.innerWidth <= 414 ? (
            {top: "2%", left: "0%"}
          ) : {}
        }
        type="ghost" 
        icon={<ArrowLeftOutlined style={{fontSize: "26px"}}/>} 
        onClick={() => props.changeForms("login")}
      />
      <Form
        className="form"
        style={{marginTop: 25, marginBottom: 0}}
        onFinish={onFinish}
        autoComplete="on"
      >
        <span className='title'>Escolha sua empresa.</span>
        <Form.Item className='companies-list'>
          {props.user && props.user.companies && props.user.companies.map((item, index) => {
            return (
              <div key={index} className={`cards ${companySelected === item.id && "active"}`} onClick={() => setCompany(item.id)}>
                <span className='label' style={{marginBottom: 5}}>{item.name}</span>
                <span className='label-cnpj'>{item.cnpj.replace(cnpjMask, "$1.***.***/$4-$5")}</span>
              </div>
            )
          })}
        </Form.Item>
        <Form.Item>
          <Button 
            className="submit" 
            type="primary" 
            htmlType="submit"
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default connector(Company);
