import { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { UserInterface } from '../../../types/user.type';
import { companiesActions } from '../../../actions/companies';
import { TransactionsInterfacePaged } from '../../../types/transactions.type';
import { BalanceHistoryInterface } from '../../../types/balance_history.type';

import TabPane from 'antd/lib/tabs/TabPane';
import MyChart from '../../../components/Chart';
import PigMoney from '../../../static/icon/pig-money.svg';
import PageHeader from '../../../components/Layout/PageHeader';
import PaperIcon from '../../../static/icon/paper-fold-text-line.svg';
import TransactionsTable from '../../../components/TransactionsTable';
import HandMoneyLinear from '../../../static/icon/hand-money-linear.svg';
import BalanceHistoryTable from '../../../components/BalanceHistoryTable';

import './styles.css';
import { MonthsHeadersInterface, MonthsTotalInterface } from '../../../types/companies.type';

interface RootState {
  authentication: {
    user: UserInterface;
  };
  user: {
    info: UserInterface;
  }
  transactions: {
    card: TransactionsInterfacePaged,
    pix: BalanceHistoryInterface[]
  };
}

const mapState = (state: RootState) => ({
  user: state.authentication.user,
  userDetails: state.user.info,
  pix_deposit: state.transactions.pix,
  transactions: state.transactions.card,
});

const actionCreators = {
  loadPixDepositHist: companiesActions.loadPixDeposit,
  loadTransactions: companiesActions.loadTransactions,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const titleCase = (string: string) => {
  const sentence = string.toLowerCase().split(" ");
  for(let i = 0; i< sentence.length; i++){
     sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

const Home = ({
  user,
  userDetails,
  pix_deposit,
  transactions, 
  loadTransactions,
  loadPixDepositHist,
}: PropsFromRedux) => {

  const [dataChart, setDataChart] = useState<MonthsTotalInterface[]>()
  const [dataHeader, setDataHeader] = useState<MonthsHeadersInterface>()

  useEffect(() => {
    (async() => {
      if (user.role === "admin") {
        console.log("opa")
      } else if(user && user.companies && user.companies.length > 0) {
        const company = user.companies[0]
        const company_id = company.id
        
        loadTransactions(company_id, 1)
        loadPixDepositHist(company_id)
  
        const monthTotals = await companiesActions.loadMonthTotals(company_id)
        const monthHeader = await companiesActions.loadMonthHeaders(company_id)
        if (monthTotals) setDataChart(monthTotals)
        if (monthHeader) setDataHeader(monthHeader)
      }
    })();
  }, []);

  const handleTitle = () => {
    if (user.role === "admin") {
      return 'Página Inicial'
    } else if (user.companies) {
      return titleCase(user.companies[0].name)
    }
    return ""
  }

  const printMoneyValue = (value: number) => {
    return value.toLocaleString("pt-br", {style: "currency", currency: "BRL"})
  }
  
  return (
    <>
      <PageHeader title={handleTitle()}/>
      {userDetails.permissions?.view_balance &&
        <>
          <div className='home-card-group'>
            <div className='home-card-item'>
              <div className='home-card-describe-content'>
                <span className='home-card-title'>Saldo disponível</span>
                <span className='home-card-describe'>{dataHeader ? printMoneyValue(Number(dataHeader.balance)) : printMoneyValue(0)}</span>
              </div>
              <img className='home-card-img' src={HandMoneyLinear} alt="" />
            </div>
            <div className='home-card-item'>
              <div className='home-card-describe-content'>
                <span className='home-card-title'>Valor a receber</span>
                <span className='home-card-describe'>{dataHeader ? printMoneyValue(Number(dataHeader.future_balance)) : printMoneyValue(0)}</span>
              </div>
              <img className='home-card-img' src={HandMoneyLinear} alt="" />
            </div>
            <div className='home-card-item'>
              <div className='home-card-describe-content'>
                <span className='home-card-title'>Saída</span>
                <span className='home-card-describe'>{dataHeader ? printMoneyValue(dataHeader.output) : printMoneyValue(0)}</span>
              </div>
              <img className='home-card-img' src={PigMoney} alt="" />
            </div>
            <div className='home-card-item'>
              <div className='home-card-describe-content'>
                <span className='home-card-title'>Máquinas Ativas</span>
                <span className='home-card-describe'>{dataHeader ? dataHeader.cardmachines : 0}</span>
              </div>
              <img className='home-card-img' src={PaperIcon} alt="" />
            </div>
          </div>
          <div className='home-content-grid'>
            <div>
              <span className='home-content-subtitle'>Últimas vendas</span>
              <Tabs>
                <TabPane tab="Cartão" key="open">
                  <div className='balance-table-content'>
                    <TransactionsTable 
                      minimal
                      top={5} 
                      active_scroll={false}
                      transactions={transactions.data} 
                      select={[
                        'transaction_date',
                        'financial_institute',
                        'original_value'
                      ]}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Pix" key="pix">
                  <div className='balance-table-content'>
                    <BalanceHistoryTable 
                      minimal
                      top={5} 
                      active_scroll={false}
                      balance_history={pix_deposit}
                      select={[
                        'date',
                        'value',
                        'operation_type',
                      ]}
                    />
                  </div>
                </TabPane>
              </Tabs>     
            </div>
            <div>
              <span className='home-content-subtitle'>Ranking de pagamentos</span>
              {dataChart ? (
                <div className='home-content-ranking'>
                  <div className='home-content-ranking-bar-chart'>
                    <MyChart data={dataChart}/>
                  </div>
                  <div className='home-content-ranking-summary-content'>
                    <div className='home-content-ranking-summary-grid'>
                      {dataChart?.sort((a,b) => b.count - a.count).map((item, index) => {
                        return (
                          <div key={index}>
                            <span className='home-content-ranking-summary-item-span'>{`${index + 1}°`}</span>
                            <div className='home-content-ranking-summary-item-content'>
                              <span className='home-content-ranking-summary-item-text'>{item.label}</span>
                              <span className='home-content-ranking-summary-item-value'>{item.count}</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <span>Carregando informações...</span>
                </div>
              )}
            </div>
          </div>
        </>
      }
    </>
  );
};

export default connector(Home);
