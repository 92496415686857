export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  ACCOUNT_REQUEST: 'USER_ACCOUNT_REQUEST',
  ACCOUNT_EXISTS: 'USER_ACCOUNT_EXISTS',
  ACCOUNT_NOT_FOUND: 'USER_ACCOUNT_NOT_FOUND',

  TOKEN_REQUEST: 'USER_TOKEN_REQUEST',
  TOKEN_VALIDATED: 'USER_TOKEN_VALIDATED',
  TOKEN_INVALIDATED: 'USER_TOKEN_INVALIDATED',

  LOGIN: 'USERS_LOGIN',
  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  LOAD_USER_INFORMATION: 'LOAD_USER_INFORMATION',

  REQUEST_SECURITY_CODE_REQUEST: 'REQUEST_SECURITY_CODE_REQUEST',
  REQUEST_SECURITY_CODE_SUCCESS: 'REQUEST_SECURITY_CODE_SUCCESS',
  REQUEST_SECURITY_CODE_FAILURE: 'REQUEST_SECURITY_CODE_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
};
