import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { LinkFormsInterface } from '..';
import { Button, Col, Row, Table, Form } from 'antd';
import { useParams } from 'react-router';
import { paymentsLinkActions } from '../../../actions/payment_link';
import { PaymentsLink } from '../../../types/payments_link';
interface LinkDetailsState {
  email: string;
}

const mapState = () => ({});

const actionCreators = {
 getLinkAction: paymentsLinkActions.getLink,
};

const connector = connect(mapState, actionCreators);


type PropsFromRedux = ConnectedProps<typeof connector>;
type LinkDetailsProps = PropsFromRedux & {
  changeForms: (formsSelected: keyof LinkFormsInterface) => void
  changeId: (value: string) => void
  changeAmount: (value: string) => void
  changeInstallmentsNumber: (value: number) => void
  
};
const LinkDetaisForms =  (props: LinkDetailsProps) => {
  const [forms, setForms] = useState<LinkDetailsState>({
    email: '',
  });
  const { linkId } = useParams();
  interface formsError {
    email: { validate: string; message: string; }
  }
  const [formsError, setError] = useState<formsError>({
    email: {validate: "", message: ""},
  }) 
  const [response, setResponse] = useState<PaymentsLink | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      if(linkId && typeof linkId === 'string'){
        props.changeId(linkId);
        
        const result = await props.getLinkAction(linkId?.toString());
        if (result && typeof result === 'object') {
          setResponse(result);
          props.changeAmount(result.amount);

          if(result.installmentsLimit && typeof result.installmentsLimit === 'number'){
            props.changeInstallmentsNumber(Number(result.installmentsLimit));
          }
        } else {
          setResponse(null);
        }
      }
    
    };
    fetchData();
  }, [props]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForms({
      ...forms,
      [name]: value,
    });
  };

  const onFinish = async () => {
 
    props.changeForms("paymentCredCard")
  
    const { email } = forms;
    if (email) {
      
    }
  };
  
  const dataSource = [
    {
      key: '1',
      descricao: response?.description,
      valor: 'R$' + Number(response?.amount).toFixed(2)
    },
  ];
  
  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
    },
  ];

  return (
    <>
      <Form
        className="form"
        onFinish={onFinish}
        autoComplete="on"
      >
        <h3 className='title'>Link para pagamento</h3>


            <div className='link-detail-card-item'>
            <Table
            showHeader={true}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            size="middle"
            className="table-value"
        />
            </div>
    
        <Form.Item
          style={{
            marginTop: 20,
          }} 
          rules={[{ required: true, message: 'Por favor digite um usuário' }]}
        >
         
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
        
                  <Row align='middle' justify='center' style={{marginBottom: 25}}>
                  <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}>
          <Button 
            className="submit" 
            type="primary" 
            htmlType="submit"
          >
           Pagar
          </Button>
          </Col>
          </Row>
       
        </Form.Item>
      </Form>
    </>
  );
};

export default connector(LinkDetaisForms);
