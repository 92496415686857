import { Action, Dispatch } from 'redux';
import { withdrawConstants } from '../../constants/withdraw.constants';
import { withdrawService } from '../../services/withdraw.service';
import { WithdrawInterface } from '../../types/withdraw.type';

const loadActiveWithdrawals = (company_id?:number) => {
  const success = (withdrawals: WithdrawInterface[]) => {
    return { type: withdrawConstants.LOAD_ACTIVE_WITHDRAWALS, withdrawals };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const withdrawals = await withdrawService.getActiveWithdrawals(company_id);
      dispatch(success(withdrawals));
    } catch (error) {
      throw error;
    }
  };
};

const createClientWithdraw = (withdraw: WithdrawInterface, type:string, company_id?:number) => {
  return async () => {
    try {
      if (type === "PIX") {
        withdraw.pix_code = withdraw.pix_type === 'PHONE' && !withdraw.pix_code?.includes("+55") ? '+55' + withdraw.pix_code : withdraw.pix_code
        await withdrawService.createClientPixWithdraw(withdraw, company_id);
        return true
      } else if (type === "BOLETO") {
        await withdrawService.createClientTicketWithdraw(withdraw);
        return true
      }
    } catch (error) {
      return false
    }
  };
};

const refuseWithdraw = (withdraw_id: number) => {
  const success = () => {
    return { type: withdrawConstants.REFUSE_WITHDRAW };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await withdrawService.refuseWithdraw(withdraw_id);
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

const concludeWithdraw = (withdraw_id: number, type:string, observation_response:string) => {
  const success = () => {
    return { type: withdrawConstants.CONCLUDE_WITHDRAW };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await withdrawService.makeReceiptPayment(withdraw_id,type, observation_response);
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

const concludeWithdrawWithoutPayment = (withdraw_id: number) => {
  const success = () => {
    return { type: withdrawConstants.CONCLUDE_WITHDRAW };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await withdrawService.concludeWithdraw(withdraw_id);
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

export const withdrawActions = {
  loadActiveWithdrawals,
  concludeWithdraw,
  refuseWithdraw,
  createClientWithdraw,
  concludeWithdrawWithoutPayment,
};








//const loadWithdrawals = (is_admin: boolean) => {
//  const success = (withdrawals: WithdrawInterface[]) => {
//    return { type: withdrawConstants.LOAD_WITHDRAWALS, withdrawals };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const withdrawals = await withdrawService.getWithdrawals(is_admin);
//      dispatch(success(withdrawals));
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const loadWithdrawalsByDate = (is_admin: boolean, start_date: string, end_date: string) => {
//  const success = (withdrawals: WithdrawInterface[]) => {
//    return { type: withdrawConstants.LOAD_WITHDRAWALS, withdrawals };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const withdrawals = await withdrawService.getWithdrawalsByDate(is_admin, start_date, end_date);
//      dispatch(success(withdrawals));
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const createWithdraw = (withdraw: WithdrawInterface, type: string) => {
//  const success = () => {
//    return { type: withdrawConstants.CREATE_WITHDRAW };
//  };
//  return async (dispatch: Dispatch<Action>) => {
//    console.log(withdraw)
//    try {
//      if (type === "PIX") {
//        await withdrawService.createPixWithdraw(withdraw);
//      }
//      dispatch(success());
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const createClientTicketWithdraw = (withdraw: ReceiptWithdrawRequestInterface) => {
//
//  return async () => {
//    try {
//      await withdrawService.createClientTicketWithdraw(withdraw);
//      return true
//    } catch (error) {
//      return false
//    }
//  };
//};
//
//const getReceiptInfo = (receipt_id: string) => {
//  const success = (clients: ReceiptInterface) => {
//    return { type: clientConstants.RECEIPT_INFO, clients };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const data = await withdrawService.getReceiptInfo(receipt_id);
//      dispatch(success(data));
//    } catch (error) {
//      throw error;
//    }
//  };
//};