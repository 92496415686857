import { ResponsivePie } from '@nivo/pie'

import './styles.css'
import { MonthsTotalInterface } from '../../types/companies.type'

interface MyChartProps {
    data: MonthsTotalInterface[]
}

const MyChart = ({
    data
}: MyChartProps) => {
    const total = data.map(item => item.count).reduce((partialSum, a) => partialSum + a, 0)

    const printMoneyValue = (value: number, reduce: boolean) => {
        let unity = ''
        if (reduce) {
            value = value / 1000
            unity = ' K'
        }

        return value.toLocaleString("pt-br", {style: "currency", currency: "BRL"}) + unity
    }

    return (
        <>
            <div className='chart-container'>
                <ResponsivePie
                    sortByValue
                    data={data}
                    colors={["#00A19B", "#0A1E41", "#F4F4F4"]}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    cornerRadius={3}
                    innerRadius={0.65}
                    padAngle={0.7}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    enableArcLabels={false}
                    enableArcLinkLabels={true}
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={10}
                    arcLinkLabelsTextOffset={4}
                    arcLinkLabel={d => printMoneyValue(d.value, true)}
                    arcLabelsTextColor={{ from: 'color' }}
                    legends={[
                        {
                            anchor: 'bottom-left',
                            direction: 'column',
                            justify: false,
                            translateY: 62,
                            itemsSpacing: 6,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#4B4B4D',
                            symbolShape: 'square',
                        }
                    ]}
                    tooltip={({
                        datum: {
                            label,
                            value
                        }
                    }) => (
                        <div style={{
                            background: "#dfdfdf",
                            opacity: 0.9,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 5,
                            paddingTop: 5,
                            borderRadius: 6
                        }}>
                            <span style={{fontWeight: 'bold'}}>{label}: </span>
                            <span>{printMoneyValue(value, false)}</span>
                        </div>
                    )}
                />
                <div className='chart-total-content'>
                    <span className='chart-total-subtitle'>Transações</span>
                    <span className='chart-total-title'>{total}</span>
                </div>
            </div>
        </>
    )
} 

export default MyChart;