import { AnyAction } from 'redux';
import { linkPaymentConstants } from '../constants/link_payments.constants';

const links = (state = { links: [], active_link: [], dirt: false }, action: AnyAction) => {
  switch (action.type) {
    case linkPaymentConstants.LOAD_LINK_PAYMENTS:
      return { ...state, links: action.links, dirt: false };
    default:
      return state;
  }
};

export default links;
