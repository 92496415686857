import { message } from 'antd';
import { Action, Dispatch } from 'redux';
import { userConstants } from '../../constants';
import { authService } from '../../services/auth.service';
import { UserInterface } from '../../types/user.type';
import { alertActions } from '../alert.actions';

export * from '../alert.actions';

const verifyAccount = (username: string, password: string) => {
  const success = (user: UserInterface) => {
    return { type: userConstants.ACCOUNT_EXISTS, user };
  };

  const failure = (error: string) => {
    return { type: userConstants.ACCOUNT_NOT_FOUND, error };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const user = await authService.verifyAccount(username, password);
      dispatch(success(user as UserInterface));
      return true
    } catch (err) {
      const error = err as Error;
      dispatch(failure(error.message));
      dispatch(alertActions.error(error.message));
    }
  };
}

const validateToken = (token: string, user: UserInterface) => {
  const success = (user: UserInterface) => {
    return { type: userConstants.TOKEN_VALIDATED, user };
  };

  const failure = (error: string) => {
    return { type: userConstants.TOKEN_INVALIDATED, error };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await authService.verifyToken(token);
      if (response) {
        dispatch(success(user as UserInterface));
      } else {
        throw("Token inválido!")
      }
      return response
    } catch (err) {
      const error = err as Error;
      dispatch(failure(error.message));
      dispatch(alertActions.error(error.message));
    }
  };
}

const login = (user: UserInterface) => {
  const success = (user: UserInterface) => {
    return { type: userConstants.LOGIN, user };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(success(user as UserInterface));
    } catch (err) {
      console.log(err)
    }
  };
}

const logout = () => {
  const success = () => {
    return { type: userConstants.LOGOUT };
  };

  return (dispatch: Dispatch<Action>) => {
    try {
      localStorage.removeItem('user');
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

const requestSecurityCode = (email: string) => {
  const request = () => {
    return { type: userConstants.REQUEST_SECURITY_CODE_REQUEST };
  };

  const success = () => {
    return { type: userConstants.REQUEST_SECURITY_CODE_SUCCESS };
  };

  const failure = (error: string) => {
    return { type: userConstants.REQUEST_SECURITY_CODE_FAILURE, error };
  };

  return async (dispatch: Dispatch<Action>) => {
    dispatch(request());

    try {
      await authService.requestSecurityCode(email);
      dispatch(success());
      return true
    } catch (err) {
      const error = err as Error;
      dispatch(failure(error.message));
      return false
    }
  };
};

const resetPassword = (code: string, id: number, password: string) => {
  const request = () => {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  };

  const success = () => {
    message.success('Senha redefinida com sucesso!');
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  };

  const failure = (error: string) => {
    message.error('Código inválido!');
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  };

  return async (dispatch: Dispatch<Action>) => {
    dispatch(request());

    try {
      await authService.resetPassword(code, id, password);
      dispatch(success());
    } catch (err) {
      const error = err as Error;
      dispatch(failure(error.message));
      dispatch(alertActions.error(error.message));
    }
  };
};

export const authActions = {
  requestSecurityCode,
  resetPassword,
  verifyAccount,
  validateToken,
  logout,
  login
};