import { AnyAction } from 'redux';
import config from '../config';
import { userConstants } from '../constants';

const user = JSON.parse(localStorage.getItem('user') as string);
const initialState = config.developMode || user ? { loggedIn: true, user } : { loggedIn: false };

const authentication = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.ACCOUNT_EXISTS:
      return {
        account: true,
        user: action.user
      }
    case userConstants.ACCOUNT_NOT_FOUND:
      return {
        account: false,
        user: action.user
      }
    case userConstants.TOKEN_VALIDATED:
      return {
        token_validate: true,
        user: action.user
      }
    case userConstants.TOKEN_INVALIDATED:
      return {
        token_validate: false,
        user: action.user
      }
    case userConstants.LOGIN:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        user: action.user,
      };
    default:
      return state;
  }
};

export default authentication;
