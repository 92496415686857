import API from '../factory/api';
import { UserInterface, UserLimitsInterface, UserPermissionsInterface } from '../types/user.type';

const getUserInformation = async (): Promise<UserInterface> => {
  try {
    const { data } = await API().post('/user/self');
    return data as UserInterface;
  } catch (error) {
    throw error;
  }
};

const updateUserInformation = async (user: UserInterface) => {
  try {
    await API().post('/user/update', user);
  } catch (error) {
    throw error;
  }
}

const updatePassword = async (password: string) => {
  try {
    await API().post(`/user/change_user_password`, { password });
  } catch (error) {
    throw error;
  }
};

const getUserByEmail = async (email:string) => {
  try {
    const response = await API().post('/user/', { email })
    if (response.status === 200) {
      return response.data as UserInterface
    }
    return false
  } catch (error) {
    return false
  }
}

const getUserById = async (user_id:number, company_id:number) => {
  try {
    const response = await API().post(`/user/checkid`, {user_id: user_id, company_id: company_id})
    if (response.status === 200) {
      return response.data as UserInterface
    }
    return false
  } catch (error) {
    return false
  }
}

const getPermissions = async (user_id:number, company_id:number) => {
  try {

    const payload = {
      user_id: user_id,
      company_id: company_id
    }

    const permissions = await API().post('/permission/get', payload)
    const limits = await API().post('/limit/get', payload)

    if (permissions.status === 200 || limits.status === 200) {
      return {
        permissions: permissions.data as UserPermissionsInterface,
        limits: limits.data as UserLimitsInterface[]
      }
    }
    return false
  } catch (error) {
    return false
  }
}

const updateAdditionalUser = async (newAdditionalUser:UserInterface, company_id: number) => {
  try {
    const response = await API().post(`/company/${company_id}/update_user_limits`, newAdditionalUser)
    if (response.status === 200) {
      console.log(response.data)
      return response.data
    }
    return false
  } catch (error) {
    return false
  }
}

export const userService = {
  getUserById,
  getPermissions,
  getUserByEmail,
  getUserInformation,
  updatePassword,
  updateAdditionalUser,
  updateUserInformation,
};
