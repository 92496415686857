import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { BalanceHistoryInterfacePaged } from '../../../types/balance_history.type';

import { Button, Checkbox, Input, Tabs } from 'antd';
import PageHeader from '../../../components/Layout/PageHeader';
import BalanceHistoryTable from '../../../components/BalanceHistoryTable';
import {ReactComponent as FilterIcon} from '../../../static/icon/filter-icon.svg';
import {ReactComponent as SearchIcon} from '../../../static/icon/search.svg';
import './styles.css';
import { companiesActions } from '../../../actions/companies';
import { UserInterface } from '../../../types/user.type';
import DataSelector from '../../../components/DataSelector';
import { CompaniesService } from '../../../services/company.service';

const { TabPane } = Tabs;

interface RootState {
  balance_history: {
    balance_history: BalanceHistoryInterfacePaged;
  }
  authentication: {
    user: UserInterface;
  }
}

const mapState = (state: RootState) => ({
  balance_history: state.balance_history.balance_history,
  user: state.authentication.user,
});

const actionCreators = {
  laodBalanceHistory: companiesActions.laodBalanceHistory,
  exportBalancehistory: companiesActions.exportBalancehistory
};
const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const periods = [
  { id: 15, days: 15 },
  { id: 30, days: 30 },
  { id: 45, days: 45 },
  { id: 60, days: 60 }
];

const BalanceHistoryPage = ({ 
  user,
  balance_history,
  exportBalancehistory
}: PropsFromRedux) => {

  const [priceValue, setInputValue] = useState<string>('');
  const [selectedPeriodId, setSelectedPeriod] = useState<number>();
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [balanceHistoryData, setBalanceHistoryData] = useState<BalanceHistoryInterfacePaged>(
    {
      data: [],
      pagination: {
        page: 0,
        per_page: 0,
        pages: 0
      }
    }
  );
  
  const getBalanceHistoryFiltered = async (
    company_id: number,
    page: number,
    start_date?: string,
    end_date?: string,
    period?: number,
    value?: string
  ) => {
    const response = await CompaniesService.getUserBalanceHistoryFilterPaged(
      company_id,
      page,
      start_date,
      end_date,
      period,
      value,
      undefined
    );
    setBalanceHistoryData(response);
  }

  const getBalanceHistory = (page: number) => {
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      //laodBalanceHistory(company_id, page);
      getBalanceHistoryFiltered(company_id, page, startDate, endDate, selectedPeriodId, priceValue);
      setCurrentPage(page);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const pattern = /^[0-9]*,?[0-9]*$/;
    if (pattern.test(value) || value === '') {
      setInputValue(value);
    }
  };

  const handleFilter = () => {
  
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id
      getBalanceHistoryFiltered(company_id, currentPage, startDate, endDate, selectedPeriodId, priceValue);
    }
    setFilterModalVisible(false);
  }

  
  useEffect(() => {
    if (user.role === "admin") {
      console.log("opa")
    } else if(user && user.companies && user.companies.length > 0) {
      //getBalanceHistory(1);
      const company_id = user.companies[0].id
      getBalanceHistoryFiltered(company_id, currentPage, startDate, endDate, selectedPeriodId, priceValue);
    }
  }, []);

  console.log(balance_history)
  
  const handleExportBalanceHistory= () => {
    debugger
    if(user && user.companies && user.companies.length > 0) {
      const company_id = user.companies[0].id as number
      (async () => {
        const file = await exportBalancehistory(company_id)
        const blob = new Blob([file], {type: "application/vnd.ms-excel"});
        const a = document.createElement('a')
        a.download = 'historico_saldo.xlsx'
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      })()
    }
  }

  

  return (
    <>
      <PageHeader title={'Histórico de Saldo'} />
        
      <Tabs className='page-header-tab'>
        <TabPane>
          <div className='balance-table-content'>
              <div className='button-filter-export-content'>
                <Button className='filter-export-button' onClick={() => handleExportBalanceHistory()}>Exportar</Button>
                <Button className='filter-export-button'
                  onClick={() => setFilterModalVisible(!filterModalVisible)}
                  type={filterModalVisible ? 'primary' : 'default'}
                >
                  <FilterIcon/>
                </Button>
                
              </div>
              <div className='balancehistory-filter-content'>
              {filterModalVisible &&
                (
                  <div className='balancehistory-filter-menu'>
                    <span><strong>Buscar por valor</strong></span>
                    <Input 
                      placeholder='Pesquisar por valor...'
                      prefix={<span style={{color:'#00A19B'}}>R$</span>}
                      suffix={<SearchIcon/>}
                      style={{borderRadius: '5px', borderBottom: '2px solid #00A19B'}}
                      value={priceValue}
                      onChange={handleChange}
                    />
                    <span><strong>Buscar por data</strong></span>
                    <DataSelector 
                      calendarIcon={false}
                      dataPickerHeight='32px'
                      dataSelectorBackgroundColor='white'
                      initialStartDate={startDate}
                      initialEndDate={endDate}
                      onStartDateChange={(date) => {
                        setStartDate(date);
                        setSelectedPeriod(undefined); // Desmarca o período selecionado ao mudar a data de início
                      }}
                      onEndDateChange={(date) => {
                        setEndDate(date);
                        setSelectedPeriod(undefined); // Desmarca o período selecionado ao mudar a data de término
                      }}
                      arrowIcon={false}
                    />
                      <div className='checkbox-filter-content' style={{
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        marginLeft: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        alignItems: 'start', // Mudado de alignContent para alignItems para um alinhamento mais efetivo
                        justifyContent: 'center'
                      }}>
                        {periods.map(period => (
                          <div key={period.id} className="custom-checkbox-wrapper">
                            <Checkbox
                                
                                checked={selectedPeriodId === period.id}
                                onChange={() => {
                                  if (selectedPeriodId === period.id) {
                                    setSelectedPeriod(undefined); // Limpa a seleção se o mesmo período for selecionado novamente
                                  } else {
                                    setSelectedPeriod(period.id); // Atualiza para o novo período selecionado
                                    setStartDate(undefined);
                                    setEndDate(undefined);
                                  }
                                }}

                              > <strong>últimos {period.days} dias</strong> </Checkbox>
                          </div>
                        ))}
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Button 
                        style={{width:'70%', backgroundColor: '#0A1E41', color:'#FFFFFF'}}
                        onClick={handleFilter}
                        size='large'
                      >
                        Aplicar filtro
                      </Button>
                      </div>
                  </div>
                )
              }
            </div>
                {balanceHistoryData &&
                  <BalanceHistoryTable 
                    balance_history={balanceHistoryData.data} 
                    pagination={balanceHistoryData.pagination} 
                    changePage={getBalanceHistory} 
                    showBalance
                  />
                }
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default connector(BalanceHistoryPage);
