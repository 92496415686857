import React from "react";
import { Button } from "antd";

import './styles.css'

interface ButtonInterface {
    kind?: "primary" | "secondary"
    label?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    loading?: boolean;
    onClick?: () => void;
    className?: string;
}

const CustomButton = ({
    kind,
    label,
    disabled,
    style,
    loading,
    className,
    onClick
}: ButtonInterface) => {
    return (
        <Button
            loading={loading}
            disabled={disabled}
            className={`container-button ${kind ? kind : "primary"} ${className}`}
            style={style}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}
export default CustomButton;