import { Action, Dispatch } from 'redux';
import { clientConstants } from '../../constants/client.constants';
import { pixService } from '../../services/pix.service';
import { PixInfoInterface } from '../../types/pix_info.type';

const getPixKeyInfo = (pix_code: string, pix_type: string) => {
  const success = (clients: PixInfoInterface) => {
    return { type: clientConstants.PIX_KEY_INFO, clients };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await pixService.getPixKeyInfo(pix_code, pix_type);
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

const getPixWithdrawalStatus = (withdrawal_id: number) => {
  const success = (status: string) => {
    return { type: clientConstants.RECEIPT_INFO, status };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await pixService.getPixWithdrawalStatus(withdrawal_id);
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};


const getPixCode = async (company_id: number) => {
  try {
    const response = await pixService.getPixCode(company_id);
    return response
  } catch (error) {
    throw error;
  }
}

export const pixActions = {
  getPixKeyInfo,
  getPixCode,
  getPixWithdrawalStatus
};
