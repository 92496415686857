import { AnyAction } from 'redux';
import { userConstants } from '../constants';

const user = (state = { info: {} }, action: AnyAction) => {
  switch (action.type) {
    case userConstants.LOAD_USER_INFORMATION:
      return { ...state, info: action.user };
    case userConstants.LOGIN:
        return { ...state, info: action.user };
    default:
      return state;
  }
};

export default user;
