import React, { useState, useEffect } from 'react'

import { pixService } from '../../../services/pix.service';

import CustomButton from '../../Button';
import CustomCheckBox from '../../Inputs/Checkbox';
import InputNumber from '../../Inputs/Number';
import InputText from '../../Inputs/Text';
import InputSelect from '../../Inputs/Select';

import '../styles.css'
import { WithdrawInterface } from '../../../types/withdraw.type';
import { withdrawActions } from '../../../actions/withdrawal';
import { connect, ConnectedProps } from 'react-redux';
import { UserInterface } from '../../../types/user.type';

interface RootState {
    authentication: {
        user: UserInterface;
    };
}

interface SelectedFavoritePixProps {
    id: number;
    pix_type: string;
    pix_code: string;
}

interface PixReceiptFormsProps {
    selectedFavoritePix?: SelectedFavoritePixProps
}

interface WithdrawInterfacePix extends WithdrawInterface {
    loadPixInfo?: boolean;
    name?: string;
    accountNumber?: string;
    favorite?: boolean;
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const actionCreators = {
    createClientWithdraw: withdrawActions.createClientWithdraw
}

const connector = connect(mapState, actionCreators)

type PropsFromRedux = ConnectedProps<typeof connector> & PixReceiptFormsProps;

const PixReceiptForms = ({
    user,
    selectedFavoritePix,
    createClientWithdraw
}:PropsFromRedux ) => {

    const { innerWidth: width } = window;
    const [confirmForms, setConfirmForms] = useState<boolean>(false)
    const [loadingPixInfo, setLoadingPixInfo] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const initialFormState = {
        value: "",
        company_id: -1,
        company_name: '',
        bankinformation_id: -1,
        bank_information_name: '',
        pix_code: '',
        pix_type: '',
        is_completed: false,
        observation_response: '',
        observation_request: '',
        name: '',
        accountNumber: '',
        loadPixInfo: false,
        favorite: false
    };
    const [forms, setForms] = useState<WithdrawInterfacePix>(initialFormState)

    interface formsError {
        pix_type: { error: boolean; message: string; };
        pix_code: { error: boolean; message: string; };
        receiver_name: { error: boolean; message: string; };
        bank_name: { error: boolean; message: string; };
        bank_account: { error: boolean; message: string; };
        value: { error: boolean; message: string; };
        favorite: { error: boolean; message: string; };
        description: { error: boolean; message: string; };
    }
    const [formsError, setError] = useState<formsError>({
        pix_type: { error: false, message: ""},
        pix_code: { error: false, message: ""},
        receiver_name: { error: false, message: ""},
        bank_name: { error: false, message: ""},
        bank_account: { error: false, message: ""},
        value: { error: false, message: ""},
        favorite: { error: false, message: ""},
        description: { error: false, message: ""},
    })

    const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setError({ ...formsError, [name]: {error: false, message: ""} })
        setForms({ ...forms, [name]: value });
    };

    const handleTypeKey = (pix_type:string) => {
        setError({ ...formsError, pix_type: {error: false, message: ""} })
        setForms({ ...forms, pix_type: pix_type })
    }

    const handleFormatCurrency = () => {
        setForms({
            ...forms,
            value: Number(forms.value).toFixed(2)
        })
    }

    const handleConfirmForms = () => {
        if (forms.pix_type === "") {
            setError({
                ...formsError,
                pix_type: {error: true, message: "Selecione um tipo de chave."}
            })
        } else if (forms.pix_code === "") {
            setError({
                ...formsError,
                pix_code: {error: true, message: "Chave inválida."}
            })
        } else if (forms.value === "" || Number(forms.value) <= 0) {
            setError({
                ...formsError,
                value: {error: true, message: "Valor deve ser maior que R$ 0,01."}
            })
        } else {
            setConfirmForms(!confirmForms)
        }        
    }

    const formatPixCode = (pix_type:string) => {
        if (pix_type === 'PHONE' && !forms.pix_code?.includes("+55")) {
            return '+55' + forms.pix_code?.replace(/[^\d]/g, '')
        } else if (pix_type === 'CPF') {
            return forms.pix_code?.replace(/[^\d]/g, '')
        }
        return String(forms.pix_code).trim()
    }

    const loadPixInfo = async() => {
        if (forms.pix_code === "" || forms.pix_type === "") return

        setLoadingPixInfo(true)
        setForms({
            ...forms,
            name: "Carregando informação...",
            bank_information_name: "Carregando informação...",
            accountNumber: "Carregando informação..."
        })

        const pix_type = forms.pix_type
        if (pix_type) {

            const pix_code = formatPixCode(pix_type)
    
            try {
                if(!pix_type || !pix_code) return
                const data = await pixService.getBS2PixKeyInfo(pix_code, pix_type);
                setForms({
                    ...forms,
                    bank_information_name: data.institutionName,
                    name: data.name,
                    accountNumber: data.accountNumber,
                    loadPixInfo: false
                })
                
            } catch (error) {
                setForms({
                    ...forms,
                    name: "Chave inválida.",
                    bank_information_name: "Chave inválida.",
                    accountNumber: "Chave inválida.",
                    loadPixInfo: false
                })
                setError({
                    ...formsError,
                    pix_code: {error: true, message: "Chave inválida."}
                })
            }

            setLoadingPixInfo(false)
        }

        setLoadingPixInfo(false)
    }

    useEffect(() => {
        if (selectedFavoritePix) {
            setForms({
                ...forms,
                pix_type: selectedFavoritePix.pix_type,
                pix_code: selectedFavoritePix.pix_code,
                loadPixInfo: true
            })
        }
    }, [selectedFavoritePix])

    useEffect(() => {
        if (forms.loadPixInfo) {
            loadPixInfo()
        }
    }, [forms.loadPixInfo])

    const submit = async () => {
        setLoadingSubmit(true)
        const withdraw = forms
        const company_id = Number(user.companies && user.companies[0].id)
        
        const response = await createClientWithdraw(withdraw, "PIX", company_id)
        
        if (response) {
            window.location.reload()
        }

        setLoadingSubmit(false)
    }

    return (
        <div className='pix-receipt-forms'>
            <div className='pix-receipt-input-group-two'>
                <div>
                    <span>Tipo de Chave Pix</span>
                    <InputSelect 
                        validate={formsError.pix_type.error ? "error" : ""}
                        errorMessage={formsError.pix_type.message}
                        value={forms.pix_type !== "" ? forms.pix_type : undefined}
                        options={[
                            {label: "Celular", value: "PHONE"},
                            {label: "E-mail", value: "EMAIL"},
                            {label: "CPF", value: "CPF"},
                            {label: "CNPJ", value: "CNPJ"},
                            {label: "Aleatória", value: "EVP"},
                        ]}
                        onChange={handleTypeKey}
                    />
                </div>
                <div>
                    <span>Chave Pix</span>
                    <InputText 
                        placeholder='Digite a chave pix do beneficiário?'
                        kind="primary"
                        name="pix_code"
                        value={forms.pix_code}
                        onChange={handleForms}
                        onBlur={loadPixInfo}
                        validate={formsError.pix_code.error ? "error" : ""}
                        errorMessage={formsError.pix_code.message}
                    />
                </div>
            </div>
            <div>
                <span>Nome do Benefeciário</span>
                <InputText 
                    disabled
                    kind="primary"
                    value={forms.name}
                />
            </div>
            <div className='pix-receipt-input-group-two'>
                <div>
                    <span>Nome da Instituição</span>
                    <InputText 
                        disabled
                        kind="primary"
                        value={forms.bank_information_name}
                    />
                </div>
                <div>
                    <span>Número da Conta</span>
                    <InputText 
                        disabled
                        kind="primary"
                        value={forms.accountNumber}
                    />
                </div>
            </div>
            <div className='pix-receipt-input-group-two'>
                <div>
                    <span>Valor a enviar</span>
                    <InputNumber
                        kind="primary"
                        prefix="R$"
                        placeholder='1.000,00'
                        name="value"
                        value={String(forms.value)}
                        onChange={handleForms}
                        onBlur={handleFormatCurrency}
                        validate={formsError.value.error ? "error" : ""}
                        errorMessage={formsError.value.message}
                    />
                </div>
                <div>
                    <span>Descrição</span>
                    <InputText 
                        kind="primary"
                        placeholder='Opcional'
                        name="observation_request"
                        value={forms.observation_request}
                        onChange={handleForms}
                    />
                </div>
            </div>
            <div className='pix-receipt-favorite-content'>
                <CustomCheckBox
                    disabled={loadingPixInfo}
                    checked={confirmForms}
                    label="Confirma os dados do beneficiário acima?"
                    onChange={handleConfirmForms}
                    labelStyle={{
                        fontWeight: "600",
                        fontSize: 14,
                        color: "#000000"
                    }}
                />
            </div>
            <div className='pix-receipt-button-content'>
                <CustomButton
                    loading={loadingSubmit}
                    disabled={!confirmForms}
                    label='ENVIAR PIX'
                    onClick={submit}
                    style={{width: width <= 433 ? "100%" : "30%"}}
                />
            </div>
        </div>
    )
}

export default connector(PixReceiptForms);