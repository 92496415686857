import React from 'react';
import { ReactComponent as RightArrowIcon } from '../../static/icon/right-arrow.svg';
import { DatePicker } from 'antd';
import moment from 'moment';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';

interface Props {
  initialStartDate?: string;
  initialEndDate?: string;
  calendarIcon?: boolean;
  arrowIcon?: boolean;
  dataPickerHeight?: string;
  dataSelectorBackgroundColor?: string;
  onStartDateChange?: (date: string) => void;
  onEndDateChange?: (date: string) => void;
}

const DateRangePicker: React.FC<Props> = ({
  initialStartDate = '',
  initialEndDate = '',
  calendarIcon = true,
  arrowIcon = true,
  dataPickerHeight = '50px',
  dataSelectorBackgroundColor = 'transparent',
  onStartDateChange,
  onEndDateChange,
}) => {
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    onStartDateChange?.(newStartDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    // Ensure both dates are compared as moment objects
    if (moment(newEndDate).isBefore(moment(startDate))) {
      // Optionally, you might want to alert the user instead of automatically setting the end date
      alert('End date cannot be before start date.');
    } else {
      setEndDate(newEndDate);
      onEndDateChange?.(newEndDate);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px' }}>
        {calendarIcon &&
        <CalendarIcon/>
        }
        <DatePicker 
          value={startDate ? moment(startDate) : null} 
          placeholder='Data Inicial'
          style={{
            background: dataSelectorBackgroundColor, 
            borderBottom: '2px solid #00A19B',
            height: dataPickerHeight,
            borderRadius: '5px'
          }}
          suffixIcon={<CalendarIcon style={{width: '12px', height:'12px'}}/>}
          onChange={(date, dateString) => {
            handleStartDateChange({ target: { value: dateString } } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
        { arrowIcon &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RightArrowIcon />
        </div>
        }
        <DatePicker 
          value={endDate ? moment(endDate) : null}
          placeholder='Data Final'
          style={{
            background: dataSelectorBackgroundColor, 
            borderBottom: '2px solid #00A19B',
            height: dataPickerHeight,
            borderRadius: '5px'
            
          }}
          suffixIcon={<CalendarIcon style={{width: '12px', height:'12px'}}/>}
          onChange={(date, dateString) => {
            handleEndDateChange({ target: { value: dateString } } as React.ChangeEvent<HTMLInputElement>);
          }}
          disabledDate={(current) => current < moment(startDate)}
        />
    </div>
  );
};

export default DateRangePicker;

/*
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ margin: '0 10px' }}>
        <input type="date" value={startDate} onChange={handleStartDateChange} style={{borderStyle: 'hidden', padding: '10px', color: '#999999'}}/>
      </label>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <RightArrowIcon />
      </div>
      <label style={{ margin: '0 10px' }}>
        <input type="date" value={endDate} onChange={handleEndDateChange} min={startDate} style={{borderStyle: 'hidden',  padding: '10px', color: '#999999'}}/>
      </label>
    </div>
*/