import React, { useRef, useState, useEffect} from 'react';
import { pixActions } from '../../actions/pix';
import { Button, Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import {QRCodeSVG} from 'qrcode.react';

import TamboretePay from '../../static/tamborete_pay.png';
import ReactToPrint from 'react-to-print';

import './styles.css'
import { UserInterface } from '../../types/user.type';
import { ConnectedProps, connect } from 'react-redux';

interface RootState {
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const PixQRCode = ({user}: PropsFromRedux) => {

    const { innerWidth: width } = window;
    const componentRef = useRef<any>();
    const [pixCode, setPixCode] = useState<string>('')

    useEffect(() => {
        const getPixCode = async () => {
            if (user.companies) {
                const company_id = Number(user.companies[0].id)
    
                const code = await pixActions.getPixCode(company_id)
                setPixCode(code.qr_code)
            }
        }
        getPixCode()
    }, [])

    return (
        <div className='pix-qr-code-container' >
            <div className='pix-qr-code-print' ref={componentRef}>
                <div className='pix-qr-code-header'>
                    <img src={TamboretePay} alt="" width={200}/>
                    <h3 className='pix-qr-code-title'>Utilize o QR code abaixo para facilitar o recebimento via Pix</h3>
                </div>
                <div className='pix-qr-code'>
                    <QRCodeSVG 
                        size={width <= 533 ? 160 : 256} 
                        value={pixCode}
                    />
                </div>
            </div>
            <div className='pix-qr-code-button-container'>
                <CopyToClipboard text={pixCode}>
                    <Tooltip placement='bottom' title="Código copiado" trigger="click" color="#001529">
                        <Button className='pix-qr-code-button'>Copiar código Pix</Button>
                    </Tooltip>
                </CopyToClipboard>
                <ReactToPrint 
                    trigger={() => {
                    return (
                        <Button className='pix-qr-code-button'>Imprimir Qr Code</Button>
                    )
                    }}
                    content={() => componentRef.current}
                />
            </div>
        </div>
    )
}
export default connector(PixQRCode);