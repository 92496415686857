import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';

import './styles.css'

const connector = connect();

type PropsFromRedux = ConnectedProps<typeof connector>;

type ResultProps = PropsFromRedux & {
    amount: string; 
};

const PaymentLinkResult: React.FC<ResultProps> = (props: ResultProps) => {
    return (
        <div className='pix-deposit-result'>
            <h2 className='payment-link-result-title'>Pagamento realizado com sucesso!</h2>
            <div className='pix-deposit-result-content'>
            <div className='link-detail-card-item'>
                <div className='pix-deposit-result-content-qr-code'>
                    <div className='pix-deposit-result-describe-content'>
                        <span className='pix-deposit-result-describe-title'>Valor pago</span>
                        <span className='pix-deposit-result-describe-value'>{Number(props.amount).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
                    </div>
                    <div className='pix-deposit-result-describe-content'>
                    <CheckCircleOutlined 
                 style={{ fontSize: '48px', color: '#00a163' }} 
                    />
                    </div>
                </div>
                </div>
            </div>
            <div className='pix-deposit-result-button-content'>

            </div>
        </div>
    )
}
export default  connector(PaymentLinkResult);