import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';

import './styles.css'

const CustomPageHeader = ({
    title,
    goTo
}: {
    title: string,
    goTo?: string | undefined
}) => {
    const navigate = useNavigate()

    return (
        <PageHeader 
            backIcon={<ArrowLeftOutlined className='page-header-arrow'/>}
            title={title} 
            className="page-header"
            onBack={goTo ? () => navigate(-1) : undefined}
        />
    )
}

export default CustomPageHeader;