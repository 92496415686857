import React, { useState } from 'react';

import { Button, Form, Row} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import InputToken from '../../../components/Inputs/Token';
import { connect, ConnectedProps } from 'react-redux';
import { AuthenticationFormsInterface } from '..';
import { UserInterface } from '../../../types/user.type';
import { authActions } from '../../../actions/auth';

interface TokenPageForms {
  token: string;
}

interface RootState {
  authentication: {
    user: UserInterface;
  };
}

const mapState = (state: RootState) => ({
  user: state.authentication.user
})

const actionCreators = {
  validateToken: authActions.validateToken,
  login: authActions.login
}

const connector = connect(mapState, actionCreators)

type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenPageProps = PropsFromRedux & {
  changeForms: (formsSelected: keyof AuthenticationFormsInterface) => void;
}

const TokenPage = (props: TokenPageProps) => {
  const [forms, setForms] = useState<TokenPageForms>({
    token: ''
  })

  const [formsError, setError] = useState<{ token: { error: boolean; message: string; } }>({
    token: {error: false, message: ""},
  }) 

  const handleChange = (value: string) => {
    setForms({ token: value });
  };

  const onFinish = async () => {
    const { token } = forms;

    if (token.length < 6) {
      setError({
        ...formsError,
        token: {
          error: true,
          message: "Token inválido."
        }
      })
    } else {
      if (props.user && props.user.role === "admin") {
        props.login(props.user)
      } else {
        const response = await props.validateToken(token, props.user)
        if (response) {
          props.changeForms("company")
        } else {
          setError({
            ...formsError,
            token: {
              error: true,
              message: "Token inválido."
            }
          })
        }
      }
    }
  }

  return (
    <>
      <Button 
        className="arrow" 
        style={
          window.innerWidth <= 414 ? (
            {top: "4%", left: "0%"}
          ) : {}
        }
        type="ghost" 
        icon={<ArrowLeftOutlined style={{fontSize: "26px"}}/>} 
        onClick={() => props.changeForms("login")}
      />
      <Form
        className="form"
        onFinish={onFinish}
        autoComplete="on"
      >
        <Row align='middle' justify='center'>
          <span className='subtitle'>Digite o token que foi enviado para o número e/ou e-mail cadastrado.</span>
          <Form.Item 
            style={{
              marginTop: 20,
            }} 
            rules={[{ required: true, message: 'Por favor digite um usuário' }]}
          >
            <InputToken 
              length={6}
              value={forms.token}
              validate={formsError.token.error ? "error": ""}
              errorMessage={formsError.token.message}
              onChange={handleChange}
            />
            <a className='link'>Reenviar token!</a>
          </Form.Item>
        </Row>
        <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
          <Button 
            className="submit" 
            type="primary" 
            htmlType="submit"
          >
            Validar token
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default connector(TokenPage);
