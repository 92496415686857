import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import './index.css';

import { store } from './helpers';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider locale={pt_BR}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,

  document.getElementById('root'),
);
