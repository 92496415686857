import { Button, Card, Form, Input, message, PageHeader } from 'antd';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import './ResetPassword.css';
import { authActions } from '../../actions/auth';

interface ResetPasswordState {
  code: string;
  password: string;
  password_confirmation: string;
  submitted: boolean;
}

const mapState = () => ({});

const actionCreators = {
  resetPassword: authActions.resetPassword,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ForgotPasswordProps = PropsFromRedux & {
  resetPassword: (code: string, id: number, password: string) => Promise<void>;
};

const ForgotPasswordPage = (props: ForgotPasswordProps) => {
  const { id } = useParams<'id'>();
  const user_id: number = id ? parseInt(id) : 0;
  const [forms, setForms] = useState<ResetPasswordState>({
    code: ``,
    password: ``,
    password_confirmation: ``,
    submitted: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForms({
      ...forms,
      [name]: value,
    });
  };

  const onFinish = () => {
    const { code, password, password_confirmation } = forms;
    if (code && password && password_confirmation) {
      if (password === password_confirmation) {
        props.resetPassword(code, user_id, password);
      } else {
        message.error('As senhas não coincidem!');
      }
    }
  };
  const equalPasswords = forms.password === forms.password_confirmation;
  return (
    <div className="reset-password-div">
      <Card className="main-card">
        <PageHeader className="site-page-header" title={'Recuperação de senha'} />
        <Form
          className="form"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="on"
          layout="vertical"
        >
          <Form.Item
            label="Código enviado por email"
            name="code"
            rules={[{ required: true, message: 'Por favor digite um código' }]}
          >
            <Input name="code" onChange={handleChange} />
          </Form.Item>

          <Form.Item
            label="Nova senha"
            name="password"
            rules={[{ required: true, message: 'Por favor digite uma senha' }]}
          >
            <Input.Password name="password" onChange={handleChange} />
          </Form.Item>

          <Form.Item
            label="Confirme a senha"
            name="password_confirmation"
            rules={[{ required: true, message: 'Por favor confirme a senha' }]}
            validateStatus={equalPasswords ? undefined : 'error'}
            help={equalPasswords ? '' : 'Por favor digite senhas iguais'}
          >
            <Input.Password name="password_confirmation" onChange={handleChange} />
          </Form.Item>

          <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
            <Link to={'/auth'}>
              <Button type="default" htmlType="submit">
                Voltar para Login
              </Button>
            </Link>
          </Form.Item>
          <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
            <Button type="primary" htmlType="submit">
              Redefinir senha
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default connector(ForgotPasswordPage);
