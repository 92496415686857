const getWithTwoDigits = (date: number): string => {
  return date < 10 ? '0' + date : date.toString();
};

export const formatDate = (date: Date): string => {
  return (
    [
      getWithTwoDigits(date.getDate()),
      getWithTwoDigits(date.getMonth() + 1),
      getWithTwoDigits(date.getFullYear()),
    ].join('/') +
    ' ' +
    [getWithTwoDigits(date.getHours()), getWithTwoDigits(date.getMinutes()), getWithTwoDigits(date.getSeconds())].join(
      ':',
    )
  );
};

export const formatDateYMD = (date: Date): string => {
  console.log(date);
  return [
    getWithTwoDigits(date.getDate()),
    getWithTwoDigits(date.getMonth() + 1),
    getWithTwoDigits(date.getFullYear()),
  ].join('/');
};

export const formatDueDate = (date: string): string => {
  return date.split('-').reverse().join('/');
}