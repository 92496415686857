import React from "react";
import { Col, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom'

import { ColumnsType } from 'antd/lib/table';

import {ReactComponent as CompanyIcon} from '../../static/icon/company.svg';
import {ReactComponent as IdentityIcon} from '../../static/icon/identity.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';

import './styles.css'
import { CompaniesInterface } from "../../types/companies.type";

interface ClientsTableProps {
    companies: CompaniesInterface[]
}

const ClientsTable = ({ companies }: ClientsTableProps) => {

    const navigate = useNavigate()

    const columns: ColumnsType<CompaniesInterface> = [
        {
            key: 'name',
            dataIndex: 'name',
            render: (record:number) => {
                return (
                    <Col className='clients-table-cell first-cell'>
                        <Row align='middle'>
                            <CompanyIcon />
                            <span className='clients-table-title'>Empresa</span>
                        </Row>
                        <Row>
                            <span className='clients-table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: 'cnpj',
            dataIndex: 'cnpj',
            render: (record:number) => {
                return (
                    <Col className='clients-table-cell'>
                        <Row align='middle'>
                            <IdentityIcon />
                            <span className='clients-table-title'>CPF/CNPJ</span>
                        </Row>
                        <Row>
                            <span className='clients-table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: 'balance',
            dataIndex: 'balance',
            render: (record:number) => {
                return (
                    <Col className='clients-table-cell last-cell'>
                        <Row align='middle'>
                            <MoneyIcon />
                            <span className='clients-table-title'>Saldo atual</span>
                        </Row>
                        <Row>
                            <span className='clients-table-value'>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
                        </Row>
                    </Col>
                )
            }
        },
    ];

    return (
        <Table
            rowKey="id"
            className="clients-table"
            size="middle"
            pagination={false}
            showHeader={false}
            columns={columns}
            dataSource={companies}
            scroll={companies && companies.length > 5 ? { x: 1000, y: 450 } : undefined}
            onRow={(record) => {
                return {
                    onClick: () => navigate(`/clientes/${record.id}`)
                };
            }}
        />
    )
}

export default ClientsTable