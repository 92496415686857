import { useState } from "react";

import { Button, message, Modal } from 'antd';

import InputSelect from "../Inputs/Select";
import { companiesActions } from "../../actions/companies";
import { connect, ConnectedProps } from "react-redux";
import InputText from "../Inputs/Text";


interface ChangeBalanceModalProps {
  open: boolean;
  company_id: number;
  onClose: () => void;
}

interface FormsProps {
  company_id: number; 
  value?: string;
  operation?: string;
}

const actionCreators = {
  updateBalance: companiesActions.updateBalance,
};

const connector = connect(null, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector> & ChangeBalanceModalProps;


const ChangeBalanceModal = (
  {
    open,
    company_id,
    onClose,
    updateBalance,
  }: PropsFromRedux
) => {

  const [loadingActive, setLoadingActive] = useState<boolean>(false)
  
  const [forms, setForms] = useState<FormsProps>({
    company_id: company_id,
    value: undefined,
    operation: undefined
  })
  
  const handleChange = (event: { target: { value: any; }; }) => {
    const { value: inputValue } = event.target;
    const numericValue = inputValue.replace(/[^\d]/g, '');

    const formattedValue = numericValue.replace(/(\d{2})$/, ',$1');
    if (inputValue == "" || Number(formattedValue.replace(',', '')*100 === 0)) {
      setForms({ ...forms, value: undefined});
      return;
    }
    setForms({ ...forms, value: formattedValue});
  };

  const handleApproveUpdateBalance = async () => {
    setLoadingActive(true);
    try {
      if (forms.value === undefined || forms.operation === undefined) {
        setLoadingActive(false);
        return;
      }
      const response = await updateBalance(forms.company_id, Number(forms.value.replace(',', '.')), forms.operation);

      if (response !== undefined && response !== null) {
        window.location.reload();
      } else {
        setLoadingActive(false);
        message.error(`Falha ao modificar saldo.`);
      }
    } catch (error) {
      setLoadingActive(false);
      message.error(`Falha ao modificar saldo.`);
    }
  }

  const ModalTitle = () => {
    return (
      <div className='add-card-machine-modal-header-content'>
        <span>Alterar Saldo</span>
      </div>
    )
  }
  
  const ModalFooter = () => {
    return (
        <div className='add-card-machine-modal-footer-content'>
            <Button className='add-card-machine-modal-button-reprove' onClick={onClose}>Cancelar</Button>
            <Button className='add-card-machine-modal-button-approve' 
              onClick={handleApproveUpdateBalance} 
              loading={loadingActive}
              disabled={forms.value === undefined || forms.operation === undefined}
            >
              Aplicar
            </Button>
        </div>
    )
  }


  return (
    <Modal
      className="add-card-machine-modal"
      title={<ModalTitle />}
      footer={<ModalFooter />}
      onCancel={onClose}
      width={400}
      open={open}
    >
      <span className="add-card-machine-modal-body-title">Alterar Saldo</span>
      <div className="add-card-machine-modal-input-group">
        <div>
          <span>Operação</span>
          <InputSelect 
            kind="secondary"
            placeholder="Selecione o tipo de operação"
            options={[{
              label: "Adicionar saldo",
              value: "add"
            }, {
              label: "Remover saldo",
              value: "sub"
            }]}
            onChange={(data) => setForms({ ...forms, operation: data })}
          />
        </div>
        <div>
          <span>Valor</span>
          <InputText
            kind='primary'
            placeholder='1000,00'
            prefix='R$'
            name="value"
            value={forms.value}
            onChange={handleChange}
        />
        </div>
      </div>
      
    </Modal>
  )
}

export default connector(ChangeBalanceModal);
