import React, { useEffect } from 'react';

import { Tabs } from 'antd';

import PageHeader from '../../../components/Layout/PageHeader';
import PixTable from '../../../components/PixTable';
import PixReceipt from '../../../components/PixReceipt';
import PixQRCode from '../../../components/PixQRCode';

const { TabPane } = Tabs;

import './styles.css';
import { WithdrawInterface } from '../../../types/withdraw.type';
import { withdrawActions } from '../../../actions/withdrawal';
import { connect, ConnectedProps } from 'react-redux';
import { UserInterface } from '../../../types/user.type';

interface RootState {
    withdraw: {
        active_withdrawals: WithdrawInterface[];
    };
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
    active_withdrawals: state.withdraw.active_withdrawals,
});

const actionCreators = {
    loadActiveWithdrawals: withdrawActions.loadActiveWithdrawals,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Pix = ({
    user,
    active_withdrawals,
    loadActiveWithdrawals
}: PropsFromRedux) => {    

    useEffect(() => {
        if (user.role !== "admin" && user.companies) {
            const company_id = user.companies[0].id
            loadActiveWithdrawals(company_id)
        } else {
            loadActiveWithdrawals()
        }
    }, [])

    return (
        <>
            <PageHeader title='Pix'/>
            <Tabs className='page-header-tab' defaultActiveKey="open">
                <TabPane tab="Retiradas em aberto" key="open">
                    <div className='pix-table-content'>
                        <PixTable withdraw={active_withdrawals}/>
                    </div>
                </TabPane>
                {user.role !== "admin" &&
                    <>
                        <TabPane tab="Meu QR Code" key="qr_code">
                            <PixQRCode />
                        </TabPane>
                        <TabPane tab="Pagamento via Pix" key="payment">
                            <PixReceipt />
                        </TabPane>
                    </>
                }
            </Tabs>
            
        </>
    )
}

export default connector(Pix);