import { AnyAction } from 'redux';
import { transactionsConstants } from '../constants/transactions.constants';

const transactions = (state = { card: [], pix: []}, action: AnyAction) => {
  switch (action.type) {
    case transactionsConstants.LOAD_TRANSACTIONS:
      return {...state, card: action.transactions};
    case transactionsConstants.LOAD_PIX_DEPOIST:
      return {...state, pix: action.pix_deposit};
    default:
      return state;
  }
};

export default transactions;
