import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Button } from "antd";

import PageHeader from "../../../components/Layout/PageHeader";
import CardProfile from "../../../components/CardProfile";
import UsersTable from "../../../components/UsersTable";

import { PlusOutlined } from '@ant-design/icons';

import './styles.css'
import { UserInterface } from "../../../types/user.type";
import { connect, ConnectedProps } from "react-redux";
import { CompaniesInterface } from "../../../types/companies.type";
import { companiesActions } from "../../../actions/companies";

interface RootState {
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const actionCreators = {
    getCompanyDetails: companiesActions.getCompanyDetails
}

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Profile = ({
    user,
    getCompanyDetails
}: PropsFromRedux) => {
    const navigate = useNavigate()
    const [company, setCompany] = useState<CompaniesInterface>()

    useEffect(() => {
        (async () => {
            if (user && user.companies) {
                const company_id = user.companies[0].id

                console.log(company_id)
                setCompany(await getCompanyDetails(company_id))
            }
        }) ()
    }, [user])

    return (
        <>
            <PageHeader title="Meus Dados"/>
            <div className="profile-container">
                <CardProfile />
                <div className="profile-content">
                    <div className="profile-additional-users-title-content">
                        <h2 className="profile-additional-users-title">Usuários</h2>
                        <Button 
                            className="user-add-button" 
                            shape="circle" 
                            size="small" 
                            icon={<PlusOutlined />} 
                            onClick={() => navigate(`/perfil/adicional/novo?company=${company && company.id}`)}
                        />
                    </div>
                    <div>
                        {company ? (
                            <UsersTable users={company?.users} company_id={company.id}/>
                        ) : (
                            <span>Carregando lista de usuários</span>
                        )}                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default connector(Profile);