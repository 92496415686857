import { Button, Input } from "antd";

import ClosedEye from '../../../static/closed-eye.png';
import OpenedEye from '../../../static/opened-eye.png';
import ClosedEyePrimary from '../../../static/closed-eye-primary.png';
import OpenedEyePrimary from '../../../static/opened-eye-primary.png';

import './styles.css'

interface InputPasswordInterface {
    kind: "ghost" | "primary";
    placeholder?: string;
    name?: string;
    label?: string;
    value?: string;
    validate?: string;
    errorMessage?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const InputPassword = ({
    kind,
    placeholder,
    label,
    name,
    value,
    validate,
    errorMessage,
    onChange
}:InputPasswordInterface ) => {

    const icons = {
        ghost: {opendEye: OpenedEye, closedEye: ClosedEye},
        primary: {opendEye: OpenedEyePrimary, closedEye: ClosedEyePrimary}
    }

    return (
        <>
            <div>
                {label && 
                    <label className="input-title">{label}</label>
                }
                <Input.Password 
                    placeholder={placeholder} 
                    name={name} 
                    className={`input input-password-${kind} ${validate}`}
                    onChange={onChange}
                    value={value}
                    iconRender={visible => (visible ? (
                        <Button className="button-input" shape="circle" size="large" icon={<img src={icons[kind].opendEye} alt=""/>}/>
                    ) : (
                        <Button className="button-input" shape="circle" size="large" icon={<img src={icons[kind].closedEye} alt=""/>}/>
                    ))}
                />
                {validate==='error' &&
                    <div className={`helper-text ${validate}`}>
                        <span style={validate === "error" ? {color: "#FF0000"} : {color: "#00C108"} }>{errorMessage}</span>
                    </div>
                }
            </div>
        </>
    )
}

export default InputPassword;