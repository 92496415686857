import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import './styles.css'

interface CustomCheckBoxInterface {
    label?: string;
    labelStyle?: React.CSSProperties,
    checked?: boolean;
    disabled?: boolean;
    onChange?: (e: CheckboxChangeEvent) => void;
}

const CustomCheckBox = ({
    label,
    labelStyle,
    checked,
    disabled,
    onChange
}: CustomCheckBoxInterface) => {
    return (
        <Checkbox
            disabled={disabled}
            checked={checked}
            className='input-checkbox'
            onChange={onChange}
        >
            <span style={labelStyle}>{label}</span>
        </Checkbox>
    )
}

export default CustomCheckBox;