import { Navigate, Outlet, useLocation } from 'react-router';

export type PrivateRouteProps = {
  isAuthenticated: boolean;
};

const PrivateRoute = ({ isAuthenticated }: PrivateRouteProps) => {
  const location = useLocation();
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth" state={{ from: location }} />;
};

export default PrivateRoute;
