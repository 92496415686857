import { TaxGroupInterface } from "../../types/tax_group.type"
import {ReactComponent as LaLaIcon } from "../../static/icon/money-green.svg";
import { Button, Col, Modal, Row, Table, message } from 'antd';
import './styles.css'
import {ReactComponent as SearchIcon} from '../../static/icon/search.svg';
import { CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from "antd/lib/table";
import { taxService } from "../../services/tax.service";
import React, { useEffect, useState } from "react";

import InputText from "../Inputs/Text";

interface CompaniesInterface{
    id: number;
    name: string;
}

const TaxTable: React.FC = () => {
    
    const [filterName, setFilterNameValue] = useState<string>("")

    const [isTaxModalVisible, setIsTaxModalVisible] = useState(false);

    const [isCompaniesTaxModalVisible, setIsCompaniesTaxModalVisible] = useState(false);

    const [isRemoveTaxModalVisible, setIsRemoveTaxModalVisible] = useState(false);

    const [data, setData] = useState<TaxGroupInterface[]>([]);

    const [modalTax, setModalTax] = useState<TaxGroupInterface | null>(null);

    const [selectedTaxGroupCompanies, setTaxGroupCompanies] = useState<CompaniesInterface[] | null>(null);

    const [loadingActive, setLoadingActive] = useState(false);

    const showTaxesModal = (tax:TaxGroupInterface) => {
        setModalTax(tax);
        setIsTaxModalVisible(true);
    }

    const handleRemoveTaxGroup = async () => {
        setLoadingActive(true);
        if (!modalTax) {
            message.error('Nenhum grupo de taxas selecionado para remoção');
            setLoadingActive(false);
            return;
        }
        try {
            const response = await taxService.removeTaxGroup(Number(modalTax.id));
            if (!response.success) {
                message.error(response.message);
                setLoadingActive(false);
            }else{
                message.success(response.message);
                const updatedData = data.filter(item => item.id !== modalTax.id);
                setData(updatedData);
            }

            setIsRemoveTaxModalVisible(false);
            setLoadingActive(false);
        
        } catch (error: any) {
            let errorMessage = 'Taxa vinculada a um ou mais clientes, não é possível remover.';
            if (error.response) {
                // Se a resposta existir e tiver uma propriedade 'data', use isso como sua mensagem de erro
                const response = error.response as { data?: { message?: string } };
                errorMessage = response.data?.message || errorMessage;
            } else if (error instanceof Error) {
                // Caso contrário, se o erro for uma instância de Error, use a mensagem de erro padrão do objeto Error
                errorMessage = error.message;
            }
            message.error(errorMessage);
            setLoadingActive(false);
            setIsRemoveTaxModalVisible(false);
        }
    }
    const showTaxgroupCompanies = async (tax:TaxGroupInterface) => {
        setModalTax(tax);
        setIsCompaniesTaxModalVisible(true);
        const fetchData = async () => {
            const response = await taxService.getCompaniesByTaxGroup(Number(tax.id));
            setTaxGroupCompanies(response);
        };
        await fetchData();
    }

    const showRemoveTaxGroupModal = (tax:TaxGroupInterface) => {
        setModalTax(tax);
        setIsRemoveTaxModalVisible(true);
    }

    const handleCancel = () => {
        setIsTaxModalVisible(false);
        setModalTax(null);
    };

    const handleCancelTaxgroupCompanies = () => {
        setIsCompaniesTaxModalVisible(false);
        setModalTax(null);
    }

    const handleCancelRemoveTax = () => {
        setLoadingActive(true);
        setIsRemoveTaxModalVisible(false);
        setLoadingActive(false);
    }

    const handleFilter = () => {
        let aux = data
        if (filterName !== "") {
          aux = aux.filter(item => {
            const tax_name = item.name.toLowerCase()
            const filter_name = filterName.toLowerCase()
            return tax_name.search(filter_name.toLowerCase()) >= 0
          })
        }
        return aux
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await taxService.getTaxGroups();
            setData(response);
        };
        fetchData();
    }, []);
    
    console.log(data);
    const columns:ColumnsType<TaxGroupInterface> = [
        {
            key: "name",
            dataIndex: "name",
            width: "70%",
            render: (record:string) => {
                return (
                    <Col className='tax-table-cell first-tax-col'>
                        <div>
                            <Row align='middle'>
                                <LaLaIcon />
                                <span className='table-title'> Taxa</span>
                            </Row>
                            <Row style={{width: 250}}>
                                <span className='table-value'> {record}</span>
                            </Row>
                        </div>
                    </Col>
                )
            }
        },
        {
            width: "10%",
            render: (record) => {
                return (
                    <Col className='tax-table-cell button-tax-col'>
                        <Button className="tax-group-action-button"
                            type="primary" 
                            size="middle"
                            style={{backgroundColor: '#46a2a5', borderColor: "#46a2a5", color: 'white', width: '100px', borderRadius: '5px'}}
                            onClick={() => showTaxesModal(record)}
                            >
                            <span>Ver taxas</span>
                        </Button>
                    </Col>
                )
            }
            
        },
        {
            width: "10%",
            render: (record) => {
                return (
                    <Col className='tax-table-cell button-tax-col'>
                        <Button className="tax-group-action-button"
                            type="primary"
                            size="middle"
                            style={{backgroundColor: '#46a2a5', borderColor: "#46a2a5", color: 'white', width: '100px', borderRadius: '5px'}}
                            onClick={() => showTaxgroupCompanies(record)}
                            >
                            <span>Clientes</span>
                        </Button>
                    </Col>
                )
            }
        },
        {
            width: "10%",
            render: (record) => {
                return (
                    <Col className='tax-table-cell button-tax-col'>
                        <Button className="tax-group-action-button"
                            type="primary"  
                            size="middle"
                            style={{backgroundColor: '#a54946', borderColor: '#a54946', color: 'white', width: '100px', borderRadius: '5px'}}
                            onClick={() => {showRemoveTaxGroupModal(record)}}
                            >
                            <span>Remover</span>
                        </Button>
                    </Col>
                )
            }
        }
    ]
    return (
        <>
            <div >
                <InputText 
                kind="primary"
                placeholder="Pesquisar por nome..."
                suffix={filterName === "" ? <SearchIcon /> : (
                    <Button 
                        shape="default"
                        size="large" 
                        icon={<CloseOutlined />}
                        className="button-input"
                        onClick={() => setFilterNameValue("")}
                    />
                )}
                value={filterName}
                onChange={(event) => setFilterNameValue(event.target.value)}
                />
            </div>
            <Table
                rowKey="id"
                className="tax-table"
                size="middle"
                pagination={false}
                showHeader={false}
                columns={columns}
                scroll={data && data.length > 5 ? { x: 1000, y: 500 } : undefined}
                dataSource={handleFilter()}
            />
        <Modal 
            title={`Visualizar taxas`} 
            open={isTaxModalVisible} 
            width={400}
            onCancel={handleCancel}
            footer={null}
            >
                <Table
                    columns={[
                        {
                            title: 'Parcelas',
                            dataIndex: 'installments',
                            key: 'installments',
                            align: 'left',
                            render: text => <div style={{padding: '0px 24px'}}>{text === 0 ? 'Débito' : `${text}x`}</div>,

                        },
                        {
                            title: 'Taxas',
                            dataIndex: 'value',
                            key: 'value',
                            align: 'left',
                            render: text => <div style={{padding: '0px 24px'}}>{`${text}%`}</div>,
                        }
                    ]}
                    dataSource={modalTax?.taxes?.slice(0)}
                    pagination={false}
                    style={{padding: "20px"}}
                />
                
        </Modal>
        <Modal 
            title={`${modalTax?.name}`} 
            open={isCompaniesTaxModalVisible} 
            width={400}
            onCancel={handleCancelTaxgroupCompanies}
            footer={null}
            >   
                <Table
                    showHeader={false}
                    columns={[
                        {
                            title: 'Id',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'left',
                            render: text => <div style={{padding: '0px 24px'}}>{`${text} `}</div>,

                        },
                        {
                            title: 'Nome',
                            dataIndex: 'name',
                            key: 'name',
                            align: 'left',
                            render: text => <div style={{padding: '0px 24px'}}>{`${text}`}</div>,
                        }
                    ]}
                    dataSource={selectedTaxGroupCompanies?.slice(0)}
                    pagination={false}   
                    style={{padding: "20px"}}
                />
                
        </Modal>
        <Modal 
            title={`Remover taxa`} 
            open={isRemoveTaxModalVisible} 
            width={400}
            onCancel={handleCancelRemoveTax}
            footer={
                <div className='payments-modal-footer-content'>
                    <Button className='payments-modal-button-reprove' onClick={handleCancelRemoveTax}>Não</Button>
                    <Button className='payments-modal-button-approve' onClick={handleRemoveTaxGroup} loading={loadingActive}>Sim</Button>
                </div>
            }
            >
               <div>
                     <p>Tem certeza que deseja remover a taxa?</p>
                     
               </div>
                
        </Modal>
    </>
    )
}

export default TaxTable