import React, { useState, useEffect } from 'react'

import {Form, Select } from 'antd';
import CustomButton from '../../Button';
import InputNumber from '../../Inputs/Number';
import InputText from '../../Inputs/Text';

import '../styles.css'
import { paymentsLinkActions } from '../../../actions/payment_link';
import { connect, ConnectedProps } from 'react-redux';
import { UserInterface } from '../../../types/user.type';
import { PaymentsLink } from '../../../types/payments_link';
import LinkPaymentsModal from "../../LinkPaymentsModal";




const { Option } = Select;
interface RootState {
    authentication: {
        user: UserInterface;
    };
}



const mapState = (state: RootState) => ({
    user: state.authentication.user,
});

const actionCreators = {
    creatLink: paymentsLinkActions.createLink
}

const connector = connect(mapState, actionCreators)

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentsLinkForms = ({
    creatLink,
    user
}:PropsFromRedux ) => {

    const { innerWidth: width } = window;
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [confirmForms, setConfirmForms] = useState<boolean>(false)
    const [openRequestCoilsModal, setOpenRequestCoilsModal] = useState<boolean>(false)
    const [url, setUrl] = useState<string>("")
    

    const initialFormState = {
        id:0,
        amount: "",
        name:"",
        description:"",
        status:1,
        url:"",
        installmentsLimit:0
    };
    const [forms, setForms] = useState<PaymentsLink>(initialFormState)

    interface formsError {
        name: { error: boolean; message: string; };
        amount: { error: boolean; message: string; };
        installmentsLimit: { error: boolean; message: string; };
    }
    const [formsError, setError] = useState<formsError>({
        name: { error: false, message: ""},
        amount: { error: false, message: ""},
        installmentsLimit: { error: false, message: ""}
    })

    const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setError({ ...formsError, [name]: {error: false, message: ""} })
        setForms({ ...forms, [name]: value });
    };



    const handleFormatCurrency = () => {
        
        setForms({
            ...forms,
            amount: Number(forms.amount).toFixed(2)
        })
    }

    const handleConfirmForms = () => {
      
        if (forms.name === "") {
            setError({
                ...formsError,
                name: {error: true, message: "Informe um nome para o link"}
            })
        }else if (forms.amount === "" || Number(forms.amount) <= 0) {
            setError({
                ...formsError,
                amount: {error: true, message: "Valor deve ser maior que R$ 0,01."}
            })
        } else {
            setConfirmForms(!confirmForms)
            submit()
        }        
    }

    const submit = async () => {

        setLoadingSubmit(true)
        const dataLink = forms
        try {
            if (user.companies) {
                const company_id = Number(user.companies[0].id)
                const link = await creatLink(dataLink,company_id)
                setUrl(link)
                setOpenRequestCoilsModal(true)
                console.log('Resposta do servidor:', link);
            }
            // Faça algo com a resposta do servidor, se necessário
          } catch (error) {
            console.error('Erro ao enviar o formulário:', error);
            // Trate o erro, se necessário
          }
   
        
       
        

        setLoadingSubmit(false)
    }
    const handleSelectChange = (value: string) => {
        setForms((prevForms) => ({
          ...prevForms,
          installmentsLimit: Number(value),
        }));
        setError({ ...formsError, "installmentsLimit": { error: false, message: '' } });
      };
    return (
        <> <LinkPaymentsModal 
        link={url}
        open={openRequestCoilsModal}
        onClose={() => setOpenRequestCoilsModal(false)}
        />
        <div className='pix-receipt-forms'>
            <div className='pix-receipt-input-group-two'>
                <div>
                    <span>Nome</span>
                    <InputText 
                        placeholder='Digite um nome para o link?'
                        kind="primary"
                        name="name"
                        value={forms.name}
                        onChange={handleForms}
                        validate={formsError.name.error ? "error" : ""}
                        errorMessage={formsError.name.message}
                    />
                </div>
                <div>
                    <span>Valor</span>
                    <InputNumber
                        kind="primary"
                        prefix="R$"
                        placeholder='1.000,00'
                        name="amount"
                        value={String(forms.amount)}
                        onChange={handleForms}
                        onBlur={handleFormatCurrency}
                        validate={formsError.amount.error ? "error" : ""}
                        errorMessage={formsError.amount.message}
                    />
                </div>
            </div>
            <div className='pix-receipt-input-group-two'>
             
                <div>
                    <span>Descrição</span>
                    <InputText 
                        kind="primary"
                        placeholder='Opcional'
                        name="description"
                        value={forms.description}
                        onChange={handleForms}
                    />
                </div>
                <div>
                    <span>Limite de parcelas</span>
                    <Form.Item>       
            <Select
              placeholder="Selecione"
              onChange={handleSelectChange}
              className={`input-select `}
            >
              <Option value="1">1x</Option>
              <Option value="2">2x</Option>
              <Option value="3">3x</Option>
              <Option value="4">4x</Option>
              <Option value="5">5x</Option>
              <Option value="6">6x</Option>
              <Option value="7">7x</Option>
              <Option value="8">8x</Option>
              <Option value="9">9x</Option>
              <Option value="10">10x</Option>
              <Option value="11">11x</Option>
              <Option value="12">12x</Option>
            </Select>
            </Form.Item>
                </div>
            </div>
            <div>
            <div className='payments-link-receipt-button-content'>
                <CustomButton
                    loading={loadingSubmit}
                    label='Gerar link'
                    onClick={handleConfirmForms}
                    style={{width: width <= 433 ? "100%" : "30%"}}
                />
            </div>
            </div>
          
        </div>
        </>
    )
}

export default connector(PaymentsLinkForms);