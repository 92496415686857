import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AuthenticationFormsInterface } from '..';
import InputText from '../../../components/Inputs/Text';
import InputPassword from '../../../components/Inputs/Password';
import { authActions } from '../../../actions/auth';

interface LoginState {
  username: string;
  password: string;
  submitted: boolean;
}
interface RootState {
  authentication: {
    loggedIn: boolean;
  };
}

const mapState = (state: RootState) => ({
  loggedIn: state.authentication.loggedIn,
});

const actionCreators = {
  verifyAccount: authActions.verifyAccount,
  logout: authActions.logout,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoginProps = PropsFromRedux & {
  verifyAccount: (username: string, password: string) => void;
  logout: () => void;
  changeForms: (formsSelected: keyof AuthenticationFormsInterface) => void
};

const LoginForms = (props: LoginProps) => {
  const [forms, setForms] = useState<LoginState>({
    username: '',
    password: '',
    submitted: false,
  });

  interface formsError {
    username: { error: boolean; message: string; }
    password: { error: boolean; message: string; }
  }
  const [formsError, setError] = useState<formsError>({
      username: {error: false, message: ""},
      password: {error: false, message: ""}
  }) 

  const regex = {
      email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setError({ ...formsError, [name]: {error: false, message: ""} })
    setForms({ ...forms, [name]: value });
  };

  const onFinish = async () => {
    const { username, password } = forms;

    if (username === "") {
      setError({
        ...formsError,
        username: {
          error: true,
          message: "E-mail não pode ser vazio."
        }
      })
    } else if (!regex.email.test(username)) {
      setError({
        ...formsError,
        username: {
          error: true,
          message: "E-mail fora do padrão."
        }
      })
    } else if (password === "") {
      setError({
        ...formsError,
        password: {
          error: true,
          message: "Senha não pode ser vazia."
        }
      })
    } else {
      const response = await props.verifyAccount(username, password);
      if (response) {
        props.changeForms("token")
      } else {
        setError({
          username: {
            error: true,
            message: "E-mail ou senha inválidos."
          },
          password: {
            error: true,
            message: "E-mail ou senha inválidos."
          }
        })
      }
    }
    
    setForms({
      ...forms,
      submitted: true,
    });
  };

  return (
    <Form
      className="form"
      onFinish={onFinish}
      autoComplete="on"
    >
      <span className="span-input">E-mail</span>
      <Form.Item>
        <InputText 
          kind='ghost'
          placeholder="Seu e-mail" 
          name="username" 
          onChange={handleChange}
          validate={formsError.username.error ? "error" : ""}
          errorMessage={formsError.username.message}
        />
      </Form.Item>
      <span className="span-input">Senha</span>
      <Form.Item>
        <InputPassword 
          kind="ghost"
          placeholder="Sua senha" 
          name="password"
          onChange={handleChange} 
          validate={formsError.password.error ? "error" : ""}
          errorMessage={formsError.password.message}
        />
        <span className='span-forgot-password'>Esqueceu sua senha? <a onClick={() => props.changeForms("forgotPassword")}>Clique aqui!</a></span>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
        <Button 
          className="submit" 
          type="primary" 
          htmlType="submit"
        >
          Continuar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default connector(LoginForms);
