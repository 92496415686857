import { Action, Dispatch } from 'redux';
import { cardMachinesConstants } from '../constants/card_machine.constants';
import { clientsService } from '../services/clients.service';
import { CardMachineInterface, CardMachineModelInterface } from '../types/card_machine.type';

const loadCardMachinesByUser = (user_id: number) => {
  const success = (card_machines: CardMachineInterface[]) => {
    return { type: cardMachinesConstants.LOAD_CARD_MACHINES, card_machines };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await clientsService.getCardMachinesByUser(user_id);
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

const loadCardMachines = () => {
  const success = (card_machines: CardMachineInterface[]) => {
    return { type: cardMachinesConstants.LOAD_CARD_MACHINES, card_machines };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await clientsService.getCardMachines();
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

const loadCardMachinesModels = () => {
  const success = (card_machine_models: CardMachineModelInterface[]) => {
    return { type: cardMachinesConstants.LOAD_MODELS, card_machine_models };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await clientsService.getCardMachineModels();
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

const createCardMachine = (company_id: number, card_machine: CardMachineInterface) => {
  return async () => {
    try {
      await clientsService.createCardMachines(company_id, card_machine);
      return true
    } catch (error) {
      return false
    }
  };
};

const createCardMachineModel = (card_machine_model: CardMachineModelInterface) => {
  const success = () => {
    return { type: cardMachinesConstants.CREATE_CARD_MACHINE_MODEL };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await clientsService.createCardMachineModel(card_machine_model);
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

const deleteCardMachine = async (user_id: number, card_machine_id: number) => {
  try {
    await clientsService.deleteCardMachines(user_id, card_machine_id);
    return true
  } catch (error) {
    return false
  }
};

const deleteCardMachineModel = (card_machine_model_id: number) => {
  const success = () => {
    return { type: cardMachinesConstants.DELETE_CARD_MACHINE_MODEL };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await clientsService.deleteCardMachineModel(card_machine_model_id);
      dispatch(success());
    } catch (error) {
      throw error;
    }
  };
};

export const cardMachinesActions = {
  loadCardMachines,
  loadCardMachinesByUser,
  loadCardMachinesModels,
  createCardMachine,
  createCardMachineModel,
  deleteCardMachine,
  deleteCardMachineModel,
};
