import { useState } from 'react';
import { Card, Col, Image, Row } from 'antd';
import Logo from '../../static/tamborete.png';

import './styles.css';
import LinkDetails from './LinkDetails';
import PaymentCredCard from './PaymentCredCard';
import Result from './Result';
export interface LinkFormsInterface {
  linkDetails: JSX.Element;
  paymentCredCard: JSX.Element;
  result: JSX.Element;
}

const LinkPage = () => {
  
  const [formsActive, setFormsActive] = useState<keyof LinkFormsInterface>('linkDetails')
  const [amount, setAmount] = useState("")
  const [idSelected, setIdSelected] = useState("")
  const [installmentsNumber, setInstallmentsNumber] = useState(12)
  
  const handleSetFormsActive = (formsSelected:keyof LinkFormsInterface) => {
    setFormsActive(formsSelected)
  }

  const handleSetIdSelected  = (value: string) => {
    setIdSelected(value);
  }
  const handleSetAmount  = (value: string) => {
    setAmount(value);
  }
  const handleInstallmentsNumber  = (value: number) => {
    setInstallmentsNumber(value);
  }
  
  const forms:LinkFormsInterface = {
    linkDetails: <LinkDetails changeForms={handleSetFormsActive} changeId={handleSetIdSelected} changeInstallmentsNumber ={handleInstallmentsNumber}  changeAmount ={handleSetAmount}  />,
    paymentCredCard: <PaymentCredCard changeForms={handleSetFormsActive} idSelected={idSelected} installmentsNumber = {installmentsNumber} />,
    result: <Result amount = {amount}/>,
  }

  return (
      <Col xs={24} sm={24} xl={24} className="container" >
        <Row align='middle' justify='center' style={{height: "100%"}}>
          <Col xs={22} sm={20} md={16} lg={14} xl={12} style={{height: "100%", display: 'flex', flexDirection: "column", justifyContent: "center"}}>
            <Card className='card'>
              <Row align='middle' justify='space-around' style={{height: "100%"}}>
                <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                  <Row align='middle' justify='center' style={{marginBottom: 25}}>
                    <Image preview={false} src={Logo} width={window.innerWidth <= 414 ? "200px" : "250px"}/>
                  </Row>
                  {forms[formsActive]}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
  );
};

export default LinkPage;