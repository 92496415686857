import React, { useState, useEffect } from "react";
import { TaxGroupInterface } from "../../types/tax_group.type";
import { taxService } from "../../services/tax.service";

import { Button, message, Modal } from 'antd';

import InputSelect from "../Inputs/Select";

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'

interface FormsProps {
  user_id: number;
  tax_group_id: number
}

interface TaxGroupModalProps {
  open: boolean;
  company_id: number;
  tax_group_id?: number;
  onClose: () => void;
}

const TaxGroupModal = ({
  open,
  company_id,
  onClose
}: TaxGroupModalProps) => {

  const [taxGroupState, setTaxGroupState] = useState<TaxGroupInterface[]>([]);
  const [loadingActive, setLoadingActive] = useState<boolean>(false)
  const [forms, setForms] = useState<FormsProps>({
    user_id: 0,
    tax_group_id: 0
  })

  useEffect(() => {
    const getTaxes = async () => {
      setTaxGroupState(await taxService.getTaxGroups());
    }
    getTaxes()
  }, []);

  const handleApproveActionPayments = async () => {
    setLoadingActive(true)
    try {
      const response = await taxService.associateTaxGroupUser(company_id, forms.tax_group_id);
      if(response) {
        window.location.reload()
      } else {
        setLoadingActive(false)
        message.error(`Falha ao alterar grupo de taxa`);
      }
    } catch (error) {
      setLoadingActive(false)
      message.error(`Falha ao alterar grupo de taxa`);
    }
  }
 
  const ModalTitle = () => {
    return (
        <div className='tax-group-modal-title-container'>
            <img src={Logo} alt="" />
        </div>
    )
  }

  const ModalFooter = () => {
    return (
        <div className='tax-group-modal-footer-content'>
            <Button className='tax-group-modal-button-reprove' onClick={onClose}>Cancelar</Button>
            <Button className='tax-group-modal-button-approve' onClick={handleApproveActionPayments} loading={loadingActive}>Alterar</Button>
        </div>
    )
  }

  const handleForms = (tax_group_id:string) => {
    setForms({ ...forms, tax_group_id: Number(tax_group_id) })
  }

  return (
    <Modal
      className="tax-group-modal"
      title={<ModalTitle />}
      footer={<ModalFooter />}
      onCancel={onClose}
      width={400}
      open={open}
    >
      <span className="tax-group-modal-body-title">Associe Taxa</span>
      <div>
        <span>Selecione grupo de taxa:</span>
        <InputSelect
          kind="secondary"
          placeholder="Selecione o grupo de taxa"
          value={String(forms.tax_group_id)}
          onChange={handleForms}
          options={taxGroupState ? taxGroupState.map(item => { return {label: item.name, value: String(item.id)} }) : [{label: "Carregando taxas", value: "-1"}]}
        />
      </div>
    </Modal>
  )
}

export default TaxGroupModal;