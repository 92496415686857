import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { Button, Collapse, message, Switch } from "antd";

import InputText from "../../../../components/Inputs/Text";
import PageHeader from "../../../../components/Layout/PageHeader";

import './styles.css'
import CustomButton from "../../../../components/Button";
import InputNumber from "../../../../components/Inputs/Number";
import { userActions } from "../../../../actions/user";
import { UserInterface, UserPermissionsInterface } from "../../../../types/user.type";
import { EditFilled, CloseOutlined } from '@ant-design/icons';
import { companiesActions } from "../../../../actions/companies";

const { Panel } = Collapse;

interface formsError {
    name: {error: boolean; message: string};
    cpf: {error: boolean; message: string};
    email: {error: boolean; message: string};
    telephone: {error: boolean; message: string};
    "PIX": {error: boolean; message: string};
    "Transferências": {error: boolean; message: string};
    "Pagamentos": {error: boolean; message: string};
}

const AdditionalProfiles = () => {
    const { method } = useParams<'method'>();
    const { search } = useLocation();
    const navigate = useNavigate()

    const ids = search.split("&").map(item => item.split("=")[1])
    const user_id = Number(ids[0])
    const company_id = method === "editar" ? Number(ids[1]) : Number(ids[0])
    const regex = {
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
    }

    const [inputActive, setInputActive] = useState<string[]>([])
    const [limitsActive, setActiveLimites] = useState<number[] | undefined>()
    const [newUser, setNewUser] = useState<boolean>(false)
    const [forms, setForms] = useState<UserInterface>({
        name: '',
        cpf: '',
        email: '',
        telephone: '',
        cellphone: '',
        address: '',
        admin: false,
        limits: [],
        permissions: {
            view_balance: false,
            view_transactions: false,
            control_limits: false
        }
    })    

    const [formsError, setError] = useState<formsError>({
        name: {error: false, message: ""},
        cpf: {error: false, message: ""},
        email: {error: false, message: ""},
        telephone: {error: false, message: ""},
        "PIX": {error: false, message: ""},
        "Transferências": {error: false, message: ""},
        "Pagamentos": {error: false, message: ""},
    })

    const operationsType = [
        {operation_type_id: 8, operation_type_name: "PIX"},
        {operation_type_id: 2, operation_type_name: "Transferências"},
        {operation_type_id: 6, operation_type_name: "Pagamentos"},
    ]

    useEffect(() =>{
        (async () => {
            if (method === "editar") {
                const user = await userActions.getUserById(user_id, company_id)
                const permissions = await userActions.getPermissions(user_id, company_id)
                
                if (user) {    
                    setForms({
                        ...forms,
                        id: user.id ? user.id : -1,
                        email: user.email ? user.email : '',
                        name: user.name ? user.name : '',
                        cpf: user.cpf ? user.cpf : '',
                        telephone: user.telephone ? user.telephone : '',
                        permissions: permissions ? permissions.permissions : undefined,
                        limits: permissions ? permissions.limits : undefined
                    })

                    if (permissions) setActiveLimites(permissions.limits?.map(item => item.operation_type_id))
                }
            }
        })()
    }, [method])

    const submit = async () => {
        const keys = Object.keys(forms)
        const emptyKeys = keys.filter(key => !["permissions", "limits", "cellphone", "address"].includes(key) && forms[key as keyof UserInterface] === "")

        if (emptyKeys.length > 0) {
            console.log("opa")
            const key = emptyKeys[0]
            setError({
                ...formsError,
                [key]: {error: true, message: "Este campo não pode ser vazio"}
            })
        } else if (forms.email && !regex.email.test(forms.email)) {
            setError({
                ...formsError,
                email: {error: true, message: "Formato de e-mail inválido"}
            })
        } else if (limitsActive?.includes(2) && forms.limits?.filter(item => item.operation_type_id === 2).length === 0) { //Transferências
            setError({
                ...formsError,
                "Transferências": {error: true, message: "O valor máximo não pode ser nulo"}
            })
        } else if (limitsActive?.includes(6) && forms.limits?.filter(item => item.operation_type_id === 6).length === 0) { //Pagamentos
            setError({
                ...formsError,
                "Pagamentos": {error: true, message: "O valor máximo não pode ser nulo"}
            })
        } else if (limitsActive?.includes(8) && forms.limits?.filter(item => item.operation_type_id === 8).length === 0) { //PIX
            setError({
                ...formsError,
                "PIX": {error: true, message: "O valor máximo não pode ser nulo"}
            })
        } else {
            if (method === "editar") {
                const response = await userActions.updateAdditionalUser(forms, company_id)
                if(response.success) createAlert("Dados atualizados com sucesso.", true)
                else createAlert("Falha ao atualizar informações do usuário. Tente novamente mais tarde.", false)
            } else {
                if(newUser) {
                    const response = await companiesActions.createUser(company_id, forms)
                    if(response.success) createAlert("Usuário cadastrado com sucesso.", true)
                    else createAlert("Falha ao cadastrar usuário. Tente novamente mais tarde.", false)
                } else {
                    const payload = {
                        user_id: forms.id,
                        limits: forms.limits,
                        permissions: forms.permissions
                    }

                    const response = await companiesActions.associateUser(company_id, payload)
                    if(response.success) createAlert("Usuário cadastrado com sucesso.", true)
                    else createAlert("Falha ao cadastrar usuário. Tente novamente mais tarde.", false)
                }
            }
        }
    }

    const createAlert = (messageAlert: string, success: boolean) => {
        if (success) message.success(messageAlert)
        else message.error(messageAlert)
        
        setTimeout(() => navigate(-1), 3000)
    }

    const handleActivePermissions = (permissionsType:keyof UserPermissionsInterface, value:boolean) => {
        setForms({
            ...forms,
            permissions: {
                ...forms.permissions,
                [permissionsType]: value
            }
        })
    }

    const handleSetLimits = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (forms.limits) {
            if (forms.limits.filter(item => item.operation_type_id === Number(name)).length > 0) {
                setForms({ 
                    ...forms, 
                    limits: forms.limits.map(item => {
                        if (item.operation_type_id === Number(name)) {
                            item.limit = Number(value)
                        }
                        return item
                    })
                })
            } else {
                setForms({ ...forms, limits: [...forms.limits, {operation_type_id: Number(name), limit: Number(value)}]})
            }
        } else {
            setForms({ ...forms, limits: [{operation_type_id: Number(name), limit: Number(value)}]})
        }
    }

    const handleGetLimits = (operation_type_id:number) => {
        const limit = forms.limits?.filter(item => item.operation_type_id === operation_type_id)

        if (limit && limit?.length > 0) {
            return limit[0].limit
        }
        return undefined
    }

    const handleActiveLimit = (operation_type_id:number) => {
        if (limitsActive) {
            if (limitsActive?.includes(operation_type_id)) {
                setActiveLimites(limitsActive.filter(item => item !== operation_type_id))
                setForms({
                    ...forms,
                    limits: forms.limits?.filter(item => item.operation_type_id !== operation_type_id)
                })
            }
            else {
                setActiveLimites([...limitsActive, operation_type_id])
            }
        } else {
            setActiveLimites([operation_type_id])
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setError({ ...formsError, [name]: {error: false, message: ""} })

        if (name === "cpf") {
            setForms({
                ...forms,
                [name as string]: value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
            })
        } else if (name === "telephone") {
            setForms({
                ...forms,
                [name as string]: value.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3")
            })
        } else {
            setForms({ ...forms, [name]: value });
        }
    }

    const handleSetInputActive = (input:string) => {
        if (!inputActive.includes(input)) {
            setInputActive([...inputActive, input])
        } else {
            const newInputActive = inputActive.filter(item => item !== input)
            setInputActive(newInputActive)
        }
    }

    const checkUser = async () => {

        if (forms.email) {
            const payload = {
                email: forms.email,
                company_id: company_id
            }
    
            const response = await companiesActions.checkUser(payload)

            console.log(response)
            
            if (!response) {
                setNewUser(true)
            } else {
                response.limits = []
                response.permissions = {
                    view_balance: false,
                    view_transactions: false,
                    control_limits: false
                }

                setForms(response)
            }
        }
    }

    const activeInput = (key: string) => {
        if (method === "editar") return !inputActive.includes(key) || !forms.email
        else if (newUser) return false
        return true
    }

    const cleanUpState = (key: keyof UserInterface) => {
        setForms({ ...forms, [key]: '' })
    }

    const renderInputButton = (key: keyof UserInterface) => {
        if (method === "editar") return <Button shape="circle" size="large" icon={!inputActive.includes(key) ? <EditFilled /> : <CloseOutlined /> } onClick={() => handleSetInputActive(key)} className="button-input" />
        else if (newUser) return <Button shape="circle" size="large" icon={<CloseOutlined />} onClick={() => cleanUpState(key)} className="button-input" />
        return ""
    }

    return (
        <>
            <PageHeader title="Vincular Usuário" goTo="/perfil"/>
            <div className="additional-container">
                <div className="additional-content">
                    <div className="additional-forms-item">
                        <span className="additional-subtitle">Dados básicos</span>
                    </div>
                    <div className="additional-forms-item">
                        <div className="additional-input-group">
                            <InputText
                                disabled={method === "editar"}
                                kind="primary"
                                label="E-mail"
                                name="email"
                                value={forms.email}
                                onChange={handleChange}
                                onBlur={checkUser}
                                validate={formsError.email.error ? "error" : ""}
                                errorMessage={formsError.email.message}
                            />
                        </div>
                        <div>
                            <InputText 
                                disabled={activeInput("name")}
                                kind="primary"
                                label="Nome"
                                name="name"
                                value={forms.name}
                                onChange={handleChange}
                                validate={formsError.name.error ? "error" : ""}
                                errorMessage={formsError.name.message}
                                suffix={renderInputButton("name")}
                            />
                        </div>
                        <div className="additional-input-group">
                            <InputText 
                                disabled={activeInput("name")}
                                kind="primary"
                                label="CPF"
                                name="cpf"
                                value={forms.cpf}
                                onChange={handleChange}
                                validate={formsError.cpf.error ? "error" : ""}
                                errorMessage={formsError.cpf.message}
                                suffix={renderInputButton("cpf")}
                            />
                            <InputText 
                                disabled={activeInput("telephone")}
                                kind="primary"
                                label="Telefone"
                                name="telephone"
                                value={forms.telephone}
                                onChange={handleChange}
                                validate={formsError.telephone.error ? "error" : ""}
                                errorMessage={formsError.telephone.message}
                                suffix={renderInputButton("telephone")}
                            />
                        </div>
                    </div>
                </div>
                <div className="additional-content">
                    <div className="additional-forms-item">
                        <span className="additional-subtitle">Configuração de limites</span>
                    </div>
                    <div>
                        <Collapse className="additional-collapse" collapsible="header" activeKey={limitsActive}>
                            {operationsType.map((item) => {
                                return (
                                    <Panel
                                        key={item.operation_type_id}
                                        header={item.operation_type_name}
                                        showArrow={false}
                                        extra={(
                                            <Switch 
                                                className="additional-collapse-switch"
                                                onChange={() => handleActiveLimit(item.operation_type_id)}
                                                checked={limitsActive?.includes(item.operation_type_id)}
                                            />
                                        )}
                                    >
                                        <div className="additional-collpase-input-group">
                                            <span className="additional-collapse-input-label">Limte Máx.:</span>
                                            <InputNumber 
                                                kind="primary"
                                                prefix="R$"
                                                name={String(item.operation_type_id)}
                                                onChange={handleSetLimits}
                                                value={String(handleGetLimits(item.operation_type_id))}
                                                style={{marginBottom: 5, width: "85%"}}
                                                validate={formsError[item.operation_type_name as keyof formsError].error ? "error" : ""}
                                                errorMessage={formsError[item.operation_type_name as keyof formsError].message}
                                            />
                                        </div>
                                    </Panel>
                                )
                            })}
                            <Panel 
                                key={100}
                                header="Saldo" 
                                showArrow={false}
                                extra={(
                                    <Switch 
                                        className="additional-collapse-switch"
                                        onChange={() => handleActivePermissions("view_balance", !forms.permissions?.view_balance)}
                                        checked={forms.permissions ? forms.permissions.view_balance : false}
                                    />    
                                )}
                            />
                            <Panel 
                                key={110}
                                header="Vendas" 
                                showArrow={false}
                                extra={(
                                    <Switch 
                                        className="additional-collapse-switch"
                                        onChange={() => handleActivePermissions("view_transactions", !forms.permissions?.view_transactions)}
                                        checked={forms.permissions ? forms.permissions.view_transactions : false}
                                    />    
                                )}
                            />
                            <Panel 
                                key={120}
                                header="Gestão de acessos" 
                                showArrow={false}
                                extra={(
                                    <Switch 
                                        className="additional-collapse-switch"
                                        onChange={() => handleActivePermissions("control_limits", !forms.permissions?.control_limits)}
                                        checked={forms.permissions ? forms.permissions.control_limits : false}
                                    />    
                                )}
                            />
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className="additional-content-button">
                <CustomButton
                    label="Salvar alterações"
                    onClick={submit}
                    style={{width: "35%"}}
                />
            </div>
        </>
    )
}
export default AdditionalProfiles;