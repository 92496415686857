import { AnyAction } from 'redux';
import { clientConstants } from '../constants/client.constants';

const clients = (state = [], action: AnyAction) => {
  switch (action.type) {
    case clientConstants.LOAD_CLIENTS:
      return action.clients;
    case clientConstants.CREATE_CLIENT:
      return [...state, { ...action.client }];
    default:
      return state;
  }
};

export default clients;
