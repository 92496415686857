import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from 'react-router-dom'

import PageHeader from "../../../components/Layout/PageHeader";
import ClientsTable from "../../../components/ClientsTable";
import InputText from "../../../components/Inputs/Text";

import {ReactComponent as SearchIcon} from '../../../static/icon/search.svg';
import { CloseOutlined } from '@ant-design/icons';

import './styles.css';
import { connect, ConnectedProps } from "react-redux";
import { CompaniesInterface } from "../../../types/companies.type";
import { companiesActions } from "../../../actions/companies";

interface RootState {
  companies: CompaniesInterface[];
}

const mapState = (state: RootState) => ({
  companies: state.companies,
});

const actionCreators = {
  listCompanies: companiesActions.listCompanies,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Clients = ({ companies, listCompanies }: PropsFromRedux) => {

  const navigate = useNavigate()

  useEffect(() => {
    listCompanies();
  }, []);

  const [filterName, setFilterNameValue] = useState<string>("")

  const removeAccent = (str:string) => {
    str = str.replace(/[àáâãäå]/g, "a")
    str = str.replace(/[èéêë]/g, "e")
    str = str.replace(/[ìíîï]/g, "i")
    str = str.replace(/[òóôõö]/g, "o")
    str = str.replace(/[ùúûü]/g, "u")
    str = str.replace(/ç/g, "c")
    return str
  }

  const handleFilter = () => {
    let aux = companies
    if (filterName !== "") {
      aux = aux.filter(item => {
        const user_name = removeAccent(item.name).toLowerCase()
        const filter_name = removeAccent(filterName).toLowerCase()
        return user_name.search(filter_name.toLowerCase()) >= 0
      })
    }

    return aux
  }

  return (
    <>
      <PageHeader title="Clientes"/>
      <div className="clients-filter-content">
        <InputText 
          kind="primary"
          placeholder="Pesquisar por nome..."
          suffix={filterName === "" ? <SearchIcon /> : (
              <Button 
                  shape="circle" 
                  size="large" 
                  icon={<CloseOutlined />}
                  className="button-input"
                  onClick={() => setFilterNameValue("")}
              />
          )}
          value={filterName}
          onChange={(event) => setFilterNameValue(event.target.value)}
        />
        <Button className="clients-button" onClick={() => navigate('/clientes/novo')}>Adicionar empresa</Button>
      </div>
      <ClientsTable companies={handleFilter()}/>
    </>
  )
}

export default connector(Clients);