import React, { useState } from 'react';
import { WithdrawInterface } from '../../types/withdraw.type';
import { connect, ConnectedProps } from 'react-redux';
import { formatDate } from '../../helpers/utils';

import { Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import PaymentsModal from '../PaymentsModal';
import PixReceiptButton from '../ReceiptButton';

import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as ProfileIcon} from '../../static/icon/profile-green.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';
import {ReactComponent as DescriptionIcon} from '../../static/icon/description-green.svg';

import './styles.css'
import { ClientInterface } from '../../types/client.type';

interface RootState {
    user: {
        info: ClientInterface;
    };
}

interface PixTableProps {
    withdraw: WithdrawInterface[]
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});


const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector> & PixTableProps;

const PixTable = ({
    user,
    withdraw
}:PropsFromRedux ) => {

    const [openModal, setOpenModal] = useState<{open: boolean; type: string, typeOfWithdraw:string, withdraw?: WithdrawInterface}>({open: false, typeOfWithdraw: "PIX", type: ""})
    const { innerWidth: width } = window;

    const columns:ColumnsType<WithdrawInterface> = [
        {
            key: "request_date",
            dataIndex: "request_date",
            width: 250,
            render: (record:number) => {
                const date = new Date(record * 1000);
                return (
                    <Col className='pix-table-cell'>
                        <Row align='middle'>
                            <CalendarIcon />
                            <span className='pix-table-title'>Data do pedido</span>
                        </Row>
                        <Row style={{width: 145}}>
                            <span className='pix-table-value'>{formatDate(date)}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "company_name",
            dataIndex: "company_name",
            width: 250,
            render: (record:string) => {
                return (
                    <Col className='pix-table-cell'>
                        <Row align='middle'>
                            <ProfileIcon />
                            <span className='pix-table-title'>Cliente</span>
                        </Row>
                        <Row style={{
                            maxWidth: width <= 991 ? 100 : "",
                            whiteSpace: "nowrap"
                        }}>
                            <span className='pix-table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "value",
            dataIndex: "value",
            width: 200,
            render: (record:string) => {
                return (
                    <Col className='pix-table-cell'>
                        <Row align='middle'>
                            <MoneyIcon />
                            <span className='pix-table-title'>Valor</span>
                        </Row>
                        <Row >
                            <span className='pix-table-value'>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "observation_request",
            dataIndex: "observation_request",
            width: 500,
            render: (record:string) => {
                return (
                    <Col className='pix-table-cell'>
                        <Row align='middle'>
                            <DescriptionIcon />
                            <span className='pix-table-title'>Observação do pedido</span>
                        </Row>
                        <Row style={{
                            maxWidth: width <= 991 ? 200 : "",
                        }}>
                            <span className='pix-table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
        {
            key: "observation_response",
            dataIndex: "observation_response",
            width: 500,
            render: (record:string) => {
                return (
                    <Col className='pix-table-cell last-cell'>
                        <Row align='middle'>
                            <DescriptionIcon />
                            <span className='pix-table-title'>Observação da aprovação</span>
                        </Row>
                        <Row style={{
                            maxWidth: width <= 991 ? 200 : "",
                            whiteSpace: "nowrap"
                        }}>
                            <span className='pix-table-value'>{record}</span>
                        </Row>
                    </Col>
                )
            }
        },
    ]

    return (
        <>
            <PaymentsModal 
                open={openModal.open}
                type={openModal.type}
                typeOfWithdraw={openModal.typeOfWithdraw}
                withdraw={openModal.withdraw}
                onClose={() => setOpenModal({...openModal, open: false})}
            />
            <Table
                rowKey="id"
                pagination={false}
                showHeader={false}
                dataSource={withdraw.filter(item => item.pix_code)} 
                columns={columns}
                size="middle"
                className="pix-table"
                expandable={{
                    expandIcon: ({expanded, onExpand, record}) => {
                        return (
                            <Col className='pix-table-cell first-cell' style={{justifyContent: "center"}}>
                                {expanded ? (
                                    <MinusSquareOutlined style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)}/>
                                ) : (
                                    <PlusSquareOutlined  style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)} />
                                )}
                            </Col>
                        )
                    },
                    expandedRowRender: (record) => {
                        return (
                        <>
                            <Col xs={24} sm={24} xl={24}>
                            <Row className='pix-table-description'>
                                <Col xs={10} sm={10} xl={10}>
                                    <Row align='middle'>
                                        <Col>
                                        <h3 className='pix-table-description-title'>Informação de pagamento</h3>
                                        <span className='pix-table-description-value'>{`Código PIX: ${record.pix_code}`}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={7} sm={7} xl={7} className="pix-table-status">
                                    <span className='pix-table-description-value'>Status do pagamento: <span className='value' style={{color: "#FFC164"}}>Em Análise</span></span>
                                </Col>
                                <Col xs={7} sm={7} xl={7} className="pix-table-actions">
                                    <Row align='middle'>
                                        {user.role === "admin" &&
                                            <>
                                                <Button className='pix-table-conclude' onClick={() => setOpenModal({...openModal, type: "conclude", open: true, withdraw: record})} >Concluir</Button>
                                                <Button className='pix-table-aprove' onClick={() => setOpenModal({...openModal, type: "approve", open: true, withdraw: record})} >Aprovar</Button>
                                                <Button className='pix-table-refuse' onClick={() => setOpenModal({...openModal, type: "reprove", open: true, withdraw: record})} >Recusar</Button>
                                            </>
                                        }
                                        {record.is_completed &&
                                            <PixReceiptButton 
                                                withdraw_id={record.id as number}
                                            />
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            </Col>
                        </>
                        )
                    } 
                }}
            />
        </>

    )
}
export default connector(PixTable);
