import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Avatar, Button } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { ClientInterface } from '../../types/client.type';
import { EditFilled } from '@ant-design/icons';

import './styles.css'

interface ProfileInterface {
    id?: number;
    name?: string;
    email?: string;
    cpf?: string
}

interface RootState {
    user: {
        info: ClientInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const CardProfile = ({
    user
}: PropsFromRedux) => {

    const [profile, setProfile] = useState<ProfileInterface>({
        id: -1,
        name: '',
        email: '',
        cpf: ''
    })

    const navigate = useNavigate()

    useEffect(() => {
        setProfile({
            id: user.id,
            name: user.name,
            email: user.email,
            cpf: user.cpf
        })
    }, [user])

    return (
        <div className='card-profile-container'>
            <Avatar 
                className='card-profile-avatar'
            >
                {profile.name ? user.name[0].substring(0, 1).toUpperCase() : "T"}
            </Avatar>
            <div className='card-profile-content'>
                <div className='card-profile-content-text'>
                    <span className='card-profile-title'>{profile.name}</span>
                    <span className='card-profile-describe'>
                        <span className='card-profile-subtitle'>CPF: </span>
                        {profile.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}
                    </span>
                    <span className='card-profile-describe'>
                        <span className='card-profile-subtitle'>E-mail: </span>
                        {profile.email}
                    </span>
                </div>
                <Button 
                    icon={<EditFilled />} 
                    shape="circle" 
                    className='table-profile-edit-button'
                    onClick={() => navigate('/perfil/me')}
                />
            </div>
        </div>
    )
}

export default connector(CardProfile);