export * from '../alert.actions';
import { Action, Dispatch } from 'redux';

import { userConstants } from '../../constants';
import { UserInterface } from '../../types/user.type';
import { userService } from '../../services/user.service';

const loadInformation = () => {
  const success = (user: UserInterface) => {
    return { type: userConstants.LOAD_USER_INFORMATION, user };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const user = await userService.getUserInformation();
      const userAuth = JSON.parse(localStorage.getItem('user') as string);
      const companyAuth = userAuth.companies[0]
      
      if (user.role !== "admin") {
        const userId = user.id && user.id
        const companyId = companyAuth.id

        if (userId && companyId) {
          const permissions = await userService.getPermissions(userId, companyId)

          if (permissions) {
            user.limits = permissions.limits
            user.permissions = permissions.permissions
          }
        }
      }

      localStorage.setItem('userDetails', JSON.stringify(user));

      dispatch(success(user));
    } catch (error) {
      throw error;
    }
  };
};

const updateUserInformation = async (user: UserInterface) => {
    try {
        await userService.updateUserInformation(user)
        return true
    } catch (error) {
        throw error;
    }
}

const updatePassword = async (password: string) => {
  try {
    await userService.updatePassword(password);
    return true
  } catch (error) {
    throw error;
  }
};

const getManagerByEmail = async (email:string) => {
  try {
    return await userService.getUserByEmail(email);
  } catch (error) {
    throw error;
  }
}

const getUserById = async (user_id:number, company_id:number) => {
  try {
    return await userService.getUserById(user_id, company_id);
  } catch (error) {
    throw error;
  }
}

const getPermissions = async (user_id:number, company_id:number) => {
  try {
    return await userService.getPermissions(user_id, company_id);
  } catch (error) {
    throw error;
  }
}

const updateAdditionalUser = async (newAdditionalUser:UserInterface, company_id:number) => {
  try {
    return await userService.updateAdditionalUser(newAdditionalUser, company_id);
  } catch (error) {
    throw error;
  }
}

export const userActions = {
  loadInformation,
  getUserById,
  getManagerByEmail,
  getPermissions,
  updatePassword,
  updateAdditionalUser,
  updateUserInformation
};

