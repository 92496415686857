import { AnyAction } from 'redux';
import { taxConstants } from '../constants/tax.constants';

const tax = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case taxConstants.LOAD_TAXES:
      return action.taxGroup;
    default:
      return state;
  }
};

export default tax;
