import { AnyAction } from 'redux';
import { balanceHistoryConstants } from '../constants/balance_history.constants';

const balance_history = (state = [], action: AnyAction) => {
  switch (action.type) {
    case balanceHistoryConstants.LOAD_BALANCE_HISTORY:
      return {...state, balance_history: action.balance_history};
    case balanceHistoryConstants.LOAD_CERTIFICATES_PAGED:
      return {...state, certificates: action.certificates};
    default:
      return state;
  }
};

export default balance_history;
