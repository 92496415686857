import API from '../factory/api';
import { BrandInterface, TaxGroupInterface, TaxInterface, TaxgroupsProps } from '../types/tax_group.type';

const getTaxGroups = async (): Promise<TaxGroupInterface[]> => {
  try {
    const { data } = await API().post(`taxgroup/list`, {});
    const group_tax = data as TaxGroupInterface[];
    return group_tax;
  } catch (error) {
    throw error;
  }
};

const createTaxGroup = async (tax_group: TaxGroupInterface) => {
  try {
    const data = await API().post(`taxgroup/create_tax_group`, tax_group);
    return data
  } catch (error) {
    throw error;
  }
}

const insertTaxList = async (tax_group_id:number,tax_group: TaxgroupsProps[]) => {
  try {
    const { data } = await API().post(`taxgroup/${tax_group_id}/insert_tax_list`, tax_group);
    return data;
  } catch (error) {
    throw error;
  }
}

const getGroupsTaxes = async (tax_group_id: number): Promise<TaxInterface[]> => {
  try {
    const { data } = await API().post(`/taxgroup/${tax_group_id}`, {});
    const taxes = data.taxes as TaxInterface[];
    return taxes;
  } catch (error) {
    throw error;
  }
};

const updatePrioritiesGroupTaxes = async (tax_group_id: number, taxes: TaxInterface[]) => {
  try {
    await API().post(`/taxgroup/${tax_group_id}/update_priorities`, { taxes: taxes });
  } catch (error) {
    throw error;
  }
};

const createTax = async (tax_group_id: number, tax: TaxInterface) => {
  try {
    await API().post(`taxgroup/${tax_group_id}/insert_tax`, tax);
  } catch (error) {
    throw error;
  }
};

const getBrands = async (): Promise<BrandInterface[]> => {
  try {
    const { data } = await API().post('/brand/list', {});
    const brands = data as BrandInterface[];
    return brands;
  } catch (error) {
    throw error;
  }
};

const getSelfTaxGroup = async (company_id?: number): Promise<TaxGroupInterface> => {
  try {
    const { data } = await API().post(`company/${company_id}/tax_group`, {});
    const taxgroup = data as TaxGroupInterface;
    return taxgroup;
  } catch (error) {
    throw error;
  }
};

const associateTaxGroupUser = async (company_id: number, tax_group_id: number) => {
  try {
    await API().post(`taxgroup/${tax_group_id}/associate_company`, {company_id: company_id} );
    return true
  } catch (error) {
    return false
  }
};

const getCompaniesByTaxGroup = async (tax_group_id: number) => {
  try {
    const { data } = await API().post(`taxgroup/${tax_group_id}/get_companies`, {});
    return data;
  } catch (error) {
    throw error;
  }
}

const removeTaxGroup = async (tax_group_id: number) => {
  try {
    const { data } = await API().post(`taxgroup/${tax_group_id}/remove_tax_group`, {});
    return data
  } catch (error) {
    throw error;
  }
}

export const taxService = {
  getSelfTaxGroup,
  getTaxGroups,
  getGroupsTaxes,
  getBrands,
  createTaxGroup,
  insertTaxList,
  updatePrioritiesGroupTaxes,
  createTax,
  associateTaxGroupUser,
  getCompaniesByTaxGroup,
  removeTaxGroup
};
