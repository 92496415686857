import React, { useEffect } from "react";
import { Tabs } from 'antd';

import PageHeader from "../../../components/Layout/PageHeader";
import BankSlipPayments from "../../../components/BankSlipPayments";
import BankSplitPaymentsTable from "../../../components/BankSplitPaymentsTable";

const { TabPane } = Tabs;

import './styles.css';
import { WithdrawInterface } from "../../../types/withdraw.type";
import { paymentsLinkActions } from "../../../actions/payment_link";
import { connect, ConnectedProps } from "react-redux";
import { UserInterface } from "../../../types/user.type";
import PaymentLinkManager from "../../../components/PaymentLinkManager";
import LinkPaymentsTable from "../../../components/LinkPaymentsTable";
import { PaymentsLink } from "../../../types/payments_link";

interface RootState {
    links: {
        links:{
            data:PaymentsLink[];
        } 
    };
    authentication: {
        user: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.authentication.user,
    active_link: state!.links!.links.data,
});

const actionCreators = {
    loadLinks: paymentsLinkActions.loadLinks,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentLink = ({
    user,
    active_link,
    loadLinks
}: PropsFromRedux) => {

    useEffect(() => {
        if (user.companies) {
            const company_id = Number(user.companies[0].id)
            loadLinks(company_id)
        }
       
    }, [])

    return (
        <>
            <PageHeader title="Pagamentos"/>
            <Tabs className='page-header-tab' defaultActiveKey="open">
                <TabPane tab="Pagamentos em aberto" key="open">
                    <div className='bank-split-table-content'>
                        <LinkPaymentsTable links={active_link}/>
                    </div>
                </TabPane>
                {user.role !== "admin" &&
                    <TabPane tab="Gerar link de pagamento" key="ticket">
                        <PaymentLinkManager />
                    </TabPane>
                }
            </Tabs>
        </>
    )
}

export default connector(PaymentLink);