import { Modal, Button, Checkbox, message } from "antd";
import Logo from '../../static/tamborete-pay-modal.png';
import DataSelector from "../DataSelector";
import { useState } from "react";
import './styles.css';

interface ExportTypeModalProps {
    open: boolean;
    onClose: () => void;
    handleExportFilter: (startDate?: string, endDate?: string, period?: number) => any;
}

const periods = [
    { id: 15, days: 15 },
    { id: 30, days: 30 },
    { id: 45, days: 45 },
    { id: 60, days: 60 }
];
  

const ExportTypeModal = ({
    open,
    onClose,
    handleExportFilter
}: ExportTypeModalProps
 ) => {
    const ModalTitle = () => {
        return (
            <div className='export-modal-title-container'>
                <img src={Logo} alt="" />
            </div>
        )
    }

    const [loadingActive, setLoadingActive] = useState<boolean>(false);

    const handleExport = async () => {
        setLoadingActive(true);
        try {
            const file = await handleExportFilter(startDate, endDate, selectedPeriodId);
            const link = document.createElement('a');
            const blob = new Blob([file.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            link.href = URL.createObjectURL(blob);
            const now = new Date().toISOString().split('T')[0].replace(/[.:T-]/g, '_');
            link.download = `relatorio_vendas_${now}.xlsx`;
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            message.success('Exportação realizada com sucesso, o download começará em breve');

        } catch (error) {
            message.error(`Erro ao exportar ${error}`);
        } finally {
            setLoadingActive(false);
            onClose();
        }
    };


    const ModalFooter = () => {
        return (
            <div className='export-modal-footer-content'>
                <Button className='export-modal-button-reprove' onClick={onClose}>Cancelar</Button>
                <Button className='export-modal-button-approve'
                    style={{width:'70%', backgroundColor: '#0A1E41', color:'#FFFFFF'}}
                    onClick={handleExport}
                    loading={loadingActive}
                >
                    Exportar
                </Button>
            </div>
        )
    }

    const [selectedPeriodId, setSelectedPeriod] = useState<number>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
 
    return (
        <Modal 
            className="export-modal"
            title={<ModalTitle />}
            footer={<ModalFooter />}
            onCancel={onClose}
            width={400}
            open={open}
        >
            
            <div className='export-menu'>
                <DataSelector 
                  calendarIcon={false}
                  dataPickerHeight='50px'
                  dataSelectorBackgroundColor='white'
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  onStartDateChange={(date) => {
                    setStartDate(date);
                    setSelectedPeriod(undefined); 

                  }}
                  onEndDateChange={(date) => {
                    setEndDate(date);
                    setSelectedPeriod(undefined); 

                  }}
                  arrowIcon={true}
                />
                  <div className='checkbox-export-content' style={{
                    paddingTop: '32px',
                    paddingBottom: '16px',
                    marginLeft: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    alignItems: 'start', 
                    justifyContent: 'center'
                  }}>
                    {periods.map(period => (
                      <div key={period.id} className="custom-checkbox-wrapper">
                        <Checkbox
                            checked={selectedPeriodId === period.id}
                            onChange={() => {
                              if (selectedPeriodId === period.id) {
                                setSelectedPeriod(undefined); 

                              } else {
                                setSelectedPeriod(period.id); 
                                (undefined);
                              }
                            }}

                          > 
                            Últimos {period.days} dias 
                          </Checkbox>
                      </div>
                    ))}
                  </div>
              </div>
        </Modal>
    );
};

export default ExportTypeModal;