import React, { useState } from 'react';
import { Modal, Button } from 'antd';

import { TransactionsInterface } from '../../types/transactions.type';

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'

interface TransactionsModalProps {
    open: boolean;
    transaction?: TransactionsInterface;
    onClose: () => void;
}
const TransactionsModal = ({
    open,
    transaction,
    onClose,
}:TransactionsModalProps) => {

    const [loadingActive, setLoadingActive] = useState(false)

    const handleApproveActionTransactions = async () => {
        setLoadingActive(true)
        console.log("cancelar venda")
        onClose()
    }

    const ModalTitle = () => {
        return (
            <div className='transactions-modal-title-container'>
                <img src={Logo} alt="" />
            </div>
        )
    }

    const ModalFooter = () => {
        return (
            <div className='transactions-modal-footer-content'>
                <Button className='transactions-modal-button-reprove' onClick={onClose}>Não</Button>
                <Button className='transactions-modal-button-approve' onClick={handleApproveActionTransactions} loading={loadingActive}>Sim</Button>
            </div>
        )
    }

    return (
        <Modal 
            className="transactions-modal"
            title={<ModalTitle />}
            footer={<ModalFooter />}
            onCancel={onClose}
            width={400}
            open={open}
        >
            <h2 className='transactions-modal-body-title'>CANCELAMENTO DE VENDA</h2>
            <span className='transactions-modal-body-subtitle'>Tem certeza que deseja cancelar a venda?</span>
            <div className='transactions-modal-body-describe'>
                <div className='transactions-modal-body-describe-item'>
                    <span className='transactions-modal-body-describe-item-title'>Dados do cliente</span>
                    <span>
                        <span>Valor: </span>
                        {transaction?.original_value}
                    </span>
                    <span>
                        <span>Data da compra: </span>
                        {transaction?.transaction_date}
                    </span>
                    <span>
                        <span>Forma de pagamento: </span>
                        {transaction && transaction.installments > 0 ? transaction?.installments : "À vista"}
                    </span>
                    <span>
                        <span>Cartão: </span>
                        Final 0000
                    </span>
                </div>
            </div>
        </Modal>
    )
}
export default TransactionsModal;