import API from '../factory/api';
import { UserInterface } from '../types/user.type';

const verifyAccount = async (username: string, password: string): Promise<UserInterface> => {
  try {
    const { data } = await API().post('/login', {}, { auth: { username, password } });
    const user = data as UserInterface;
    return user;
  } catch (error) {
    logout();
    throw error;
  }
};

const verifyToken = async (token: string) => {
  try {
    if (token === '379325') {
      //localStorage.setItem('user', JSON.stringify(user));
      return true;
    }
    return false
  } catch (error) {
    logout();
    throw error;
  }
};

const logout = () => {
  localStorage.removeItem('user');
};

const requestSecurityCode = async (email: string) => {
  try {
    await API().post(`/user/request_reset_password`, { email });
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (code: string, id: number, password: string) => {
  try {
    await API().post(`/user/reset_password`, { code, id, password });
  } catch (error) {
    throw error;
  }
};

export const authService = {
    verifyAccount,
    verifyToken,
    logout,
    requestSecurityCode,
    resetPassword
  };