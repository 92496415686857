import { AnyAction } from 'redux';
import { bankAccountsConstants } from '../constants/bank_accounts.constants';

const bank_accounts = (state = { accounts: [], dirt: false }, action: AnyAction) => {
  switch (action.type) {
    case bankAccountsConstants.LOAD_BANK_ACCOUNTS:
      return { ...state, accounts: action.bank_accounts, dirt: false };
    case bankAccountsConstants.CREATE_BANK_ACCOUNT:
    case bankAccountsConstants.DELETE_BANK_ACCOUNT:
      return { ...state, dirt: true };
    default:
      return state;
  }
};

export default bank_accounts;
