import { Button, Form, Col, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import InputText from '../../../components/Inputs/Text';
import { paymentsLinkActions } from '../../../actions/payment_link';
import { LinkFormsInterface } from '..';
import { TransationCard } from '../../../types/transation_card';
import InputDate from '../../../components/Inputs/Date';
const { Option } = Select;

interface PatmentCredCardState {
  amount: string;
  nome: string;
  email: string;
  dateOfbirth: string;
  document: string;
  installments: string;
  cardNumber: string;
  cardExpirationDate: string;
  cardHolderName: string;
  cardCvv: string;
  submitted: boolean;
  idLink:string;
}

interface RootState {
  authentication: {
    loggedIn: boolean;
  };
}

const mapState = (state: RootState) => ({
  loggedIn: state.authentication.loggedIn,
});

const actionCreators = {
  trasation: paymentsLinkActions.transation,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoginProps = PropsFromRedux & {
  trasation: (transation: TransationCard) => Promise<boolean>;
  changeForms: (formsSelected: keyof LinkFormsInterface) => void;
  idSelected: string; 
  installmentsNumber: number; 
};

const LoginForms: React.FC<LoginProps> = (props: LoginProps) => {
  const [forms, setForms] = useState<PatmentCredCardState>({
    amount: '',
    dateOfbirth: '',
    document: '',
    nome: '',
    email: '',
    installments: '',
    cardNumber: '',
    cardExpirationDate: '',
    cardHolderName: '',
    cardCvv: '',
    idLink:'',
    submitted: false,
  });

  interface formsError {
    amount: { error: boolean; message: string; };
    dateOfbirth: { error: boolean; message: string; };
    document: { error: boolean; message: string; };
    nome: { error: boolean; message: string; };
    email: { error: boolean; message: string; };
    installments: { error: boolean; message: string; };
    cardNumber: { error: boolean; message: string; };
    cardHolderName: { error: boolean; message: string; };
    cardCvv: { error: boolean; message: string; };
    cardExpirationDate: { error: boolean; message: string; };
  }

  const [formsError, setError] = useState<formsError>({
    amount: { error: false, message: '' },
    dateOfbirth: { error: false, message: '' },
    document: { error: false, message: '' },
    nome: { error: false, message: '' },
    email: { error: false, message: '' },
    installments: { error: false, message: '' },
    cardNumber: { error: false, message: '' },
    cardHolderName: { error: false, message: '' },
    cardCvv: { error: false, message: '' },
    cardExpirationDate: { error: false, message: '' },
  });

  useEffect(() => {
    console.log('Forms state updated:', forms);
  }, [forms]);
  const handleDate = (date: moment.Moment | null) => {
    if (date) {
        setForms({ ...forms, dateOfbirth: date.format("YYYY-MM-DD") })
    }
}

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(`Field changed: ${name}, Value: ${value}`);
    setError({ ...formsError, [name]: { error: false, message: '' } });
    if (name === 'document') {
      console.log('Setting document field to 555');
      setForms((prevForms) => ({
        ...prevForms,
        [name]: value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
      }));
    }else if (name === 'cardExpirationDate'){

      setForms((prevForms) => ({
        ...prevForms,
        [name]: value.replace(/(\d{2})(\d{2})/, "$1/$2")

      }));
    } 
    
    else {
      setForms((prevForms) => ({
        ...prevForms,
        [name]: value,
      }));
    }
    
  };

  const handleSelectChange = (value: string) => {
    setForms((prevForms) => ({
      ...prevForms,
      installments: value,
    }));
    setError({ ...formsError, installments: { error: false, message: '' } });
  };

  const onFinish = async () => {
  
    const data = forms;
    data.amount = '1'
    if (data.amount === '') {
      setError({
        ...formsError,
        amount: {
          error: true,
          message: 'Valor é um campo obrigatório',
        },
      });
    } else if (data.nome === '') {
      setError({
        ...formsError,
        nome: {
          error: true,
          message: 'Nome é um campo obrigatório',
        },
      });
    } else if (data.email === '') {
      setError({
        ...formsError,
        email: {
          error: true,
          message: 'Email é um campo obrigatório',
        },
      });
    } else if (data.installments === '') {
      setError({
        ...formsError,
        installments: {
          error: true,
          message: 'Selecione o número de parcelas.',
        },
      });
    } else if (data.cardNumber === '') {
      setError({
        ...formsError,
        cardNumber: {
          error: true,
          message: 'Digite o número do cartão.',
        },
      });
    } else if (data.cardExpirationDate === '') {
      setError({
        ...formsError,
        cardExpirationDate: {
          error: true,
          message: 'Digite a data de expiração',
        },
      });
    } else if (data.cardCvv === '') {
      setError({
        ...formsError,
        cardCvv: {
          error: true,
          message: 'Código de segurança obrigatório',
        },
      });
    } else if (data.cardHolderName === '') {
      setError({
        ...formsError,
        cardHolderName: {
          error: true,
          message: 'Digite o nome do titular do cartão.',
        },
      });
    } else {
      debugger
      data.idLink = props.idSelected;
      const response = await props.trasation(data);
      debugger
      if (response) {
        props.changeForms('result');
      } else {
        // Todo: mensagem de erro na transação
      }
    }

    setForms({
      ...forms,
      submitted: true,
    });
  };
  const gerarLista = (n: number) => {
    return Array.from({ length: n }, (_, i) => i + 1);
  };
  return (
    <Form className="form" onFinish={onFinish} autoComplete="on">
      <Row align='middle' justify='center'>
        <Col xs={24}>
          <span className="span-input">Nome Completo</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="Digite seu nome"
              name="nome"
              onChange={handleChange}
              validate={formsError.nome.error ? 'error' : ''}
              errorMessage={formsError.nome.message}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align='middle' justify='center'>
        <Col xs={24}>
          <span className="span-input">Email</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="Digite seu e-mail"
              name="email"
              onChange={handleChange}
              validate={formsError.email.error ? 'error' : ''}
              errorMessage={formsError.email.message}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align='middle' justify='center'>
        <Col xs={12}>
          <span className="span-input">Cpf</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="Digite o número do seu CPF"
              name="document"
              value= {forms.document}
              onChange={handleChange}
              validate={formsError.document.error ? 'error' : ''}
              errorMessage={formsError.document.message}
              style={{ width: '95%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <span className="span-input">Data de Nascimento</span>
          <Form.Item>
          <InputDate 
                                
                                onChange={handleDate}
                            />
      
          </Form.Item>
        </Col>
      </Row>
      <Row align='middle' justify='center'>
        <Col xs={24}>
          <span className="span-input">Nome como no cartão</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="Insira seu nome como impresso no cartão"
              name="cardHolderName"
              onChange={handleChange}
              validate={formsError.cardHolderName.error ? 'error' : ''}
              errorMessage={formsError.cardHolderName.message}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align='middle' justify='center'>
        <Col xs={24}>
          <span className="span-input">Número do cartão</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="Número do cartão"
              name="cardNumber"
              onChange={handleChange}
              validate={formsError.cardNumber.error ? 'error' : ''}
              errorMessage={formsError.cardNumber.message}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align='middle' justify='center' >
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <span className="span-input">Data de expiração</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="MM/YY"
              value={forms.cardExpirationDate}
              name="cardExpirationDate"
              onChange={handleChange}
              validate={formsError.cardExpirationDate.error ? 'error' : ''}
              errorMessage={formsError.cardExpirationDate.message}
              style={{width: "95%"}}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
          <span className="span-input">Código de segurança</span>
          <Form.Item>
            <InputText
              kind='ghost'
              placeholder="CVV"
              name="cardCvv"
              onChange={handleChange}
              validate={formsError.cardCvv.error ? 'error' : ''}
              errorMessage={formsError.cardCvv.message}
              style={{width: "95%"}}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
          <span className="span-input">Parcelas</span>
          <Form.Item>
            <Select
              placeholder="Selecione"
              onChange={handleSelectChange}
              value={forms.installments}
              className={`input-select `}
            >
                   {gerarLista(props.installmentsNumber).map((numero) => (
          <option key={numero} value={numero}>
            {numero}x
          </option>
        ))}
           
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
        <Button 
          className="submit" 
          type="primary" 
          htmlType="submit"
        >
          Realizar pagamento
        </Button>
      </Form.Item>
    </Form>
  );
};

export default connector(LoginForms);
