import React from 'react'

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { CardMachineInterface } from '../../types/card_machine.type';

import {ReactComponent as NsuIcon} from '../../static/icon/nsu.svg';
import {ReactComponent as ModelIcon} from '../../static/icon/model.svg';
import {ReactComponent as CardMachinesIcon} from '../../static/icon/card-machine.svg';

import './styles.css'
import { cardMachinesActions } from '../../actions_copy/card_machine.actions';
import InputCheckbox from '../Inputs/Checkbox';

interface CardMachineTableProps {
  user_id?: number;
  card_machines: CardMachineInterface[];
  is_admin?: boolean;
  rowSelected?: number[];
  onSelect?: (id: number) => void
}

const CardMachineTable = ({ 
  card_machines,
  user_id,
  is_admin,
  rowSelected,
  onSelect
}: CardMachineTableProps) => {

  const columns: ColumnsType<CardMachineInterface> = [
    {
      key: 'serial_number',
      dataIndex: 'serial_number',
      render: (record:number) => {
        return (
          <Col className={`card-machines-table-cell ${is_admin ? 'first-cell' : ''}`}>
            <Row align='middle'>
              <NsuIcon />
              <span className='card-machines-table-title'>Número de Série</span>
            </Row>
            <Row>
              <span className='card-machines-table-value'>{record}</span>
            </Row>
          </Col>
        )
      }
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (record:string) => {
        return (
          <Col className='card-machines-table-cell'>
            <Row align='middle'>
              <ModelIcon />
              <span className='card-machines-table-title'>Modelo</span>
            </Row>
            <Row>
              <span className='card-machines-table-value'>{record}</span>
            </Row>
          </Col>
        )
      }
    },
    {
      key: 'model_name',
      dataIndex: 'model_name',
      render: (record:string) => {
        return (
          <Col className={`card-machines-table-cell ${is_admin ? '' : 'last-cell'}`}>
            <Row align='middle'>
              <CardMachinesIcon />
              <span className='card-machines-table-title'>Nome da Máquina</span>
            </Row>
            <Row>
              <span className='card-machines-table-value'>{record}</span>
            </Row>
          </Col>
        )
      }
    },
  ];

  const handleDeleteCardMachine = async(card_machine_id:number) => {
    if(!user_id) return

    try {
      const response = await cardMachinesActions.deleteCardMachine(user_id, card_machine_id)

      if(response) {
        window.location.reload()
      } else {
        message.error(`Falha ao alterar grupo de taxa`);
      }

    } catch (error) {
      message.error(`Falha ao alterar grupo de taxa`);
    }
  }

  if (is_admin) {
    columns.push({
      key: 'action',
      title: '',
      width: 60,
      render: (record) => {
        return (
          <Col className='card-machines-table-cell last-cell'>
            <Row align='middle'>
              <Button 
                shape="circle" 
                size="large" 
                icon={<DeleteOutlined />}
                className="button-input"
                onClick={() => handleDeleteCardMachine(record.id)}
              />
            </Row>
          </Col>
        )
      },
    });
  }

  return (
    <div>
      <Table
        size="middle"
        rowKey='serial_number'
        className="card-machines-table"
        pagination={false}
        showHeader={false}
        columns={columns}
        dataSource={card_machines}
        scroll={{ x: 1000 }}
        rowSelection={is_admin ? undefined : {
          type: 'checkbox',
          renderCell: (value, record) => {
            if(value) console.log(value)
            if(!record.id) return
            return (
              <>
                <div className='card-machines-table-cell-checkbox first-cell'>
                  <InputCheckbox 
                    checked={rowSelected && rowSelected.includes(record.id)}
                    onChange={onSelect ? () => onSelect(Number(record.id)) : undefined}
                  />
                </div>
              </>
            )
          }
        }}
      />
    </div>
  );
};

export default CardMachineTable;
