import React, { useState, useEffect } from "react";

import { Button, message, Modal } from 'antd';

import InputSelect from "../Inputs/Select";
import InputText from "../Inputs/Text";

import Logo from '../../static/tamborete-pay-modal.png';

import './styles.css'
import { CardMachineModelInterface } from "../../types/card_machine.type";
import { cardMachinesActions } from "../../actions_copy/card_machine.actions";
import { connect, ConnectedProps } from "react-redux";

interface FormsProps {
  serial_number: string;
  model_id: number;
  name: string;
  company_id: number;
}

interface AddCardMachineModalProps {
  open: boolean;
  company_id: number;
  onClose: () => void;
}

interface RootState {
  card_machines: {
    models: CardMachineModelInterface[];
  };
}

const mapState = (state: RootState) => ({
  card_machines_models: state.card_machines.models,
});

const actionCreators = {
  loadCardMachinesModels: cardMachinesActions.loadCardMachinesModels,
  createCardMachine: cardMachinesActions.createCardMachine,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector> & AddCardMachineModalProps;

const AddCardMachineModal = ({
  open,
  company_id,
  card_machines_models,
  onClose,
  loadCardMachinesModels,
  createCardMachine
}: PropsFromRedux) => {

  const [loadingActive, setLoadingActive] = useState<boolean>(false)
  const [forms, setForms] = useState<FormsProps>({
    serial_number: "",
    model_id: 0,
    name: "",
    company_id: company_id
  })

  const handleApproveActionPayments = async () => {
    setLoadingActive(true)
    try {
      const response = await createCardMachine(company_id, forms)

      if (response) {
        window.location.reload()
      } else {
        setLoadingActive(false)
        message.error(`Falha ao adicionar máquina.`);
      }

    } catch (error) {
      setLoadingActive(false)
      message.error(`Falha ao adicionar máquina.`);
    }
  }
 
  const ModalTitle = () => {
    return (
        <div className='add-card-machine-modal-title-container'>
          <img src={Logo} alt="" />
        </div>
    )
  }

  const ModalFooter = () => {
    return (
        <div className='add-card-machine-modal-footer-content'>
            <Button className='add-card-machine-modal-button-reprove' onClick={onClose}>Cancelar</Button>
            <Button className='add-card-machine-modal-button-approve' onClick={handleApproveActionPayments} loading={loadingActive}>Adicionar</Button>
        </div>
    )
  }

  const handleForms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForms({ ...forms, [name]: value });
  };

  useEffect(() => {
    loadCardMachinesModels()
  }, [])
  
  return (
    <Modal
      className="add-card-machine-modal"
      title={<ModalTitle />}
      footer={<ModalFooter />}
      onCancel={onClose}
      width={400}
      open={open}
    >
      <span className="add-card-machine-modal-body-title">Adicionar Máquina</span>
      <div className="add-card-machine-modal-input-group">
        <div>
          <span>Número de série</span>
          <InputText 
            kind="secondary"
            placeholder="Digite o número de serie"
            name="serial_number"
            value={forms.serial_number}
            onChange={handleForms}
          />
        </div>
        <div>
          <span>Modelo</span>
          <InputSelect 
            kind="secondary"
            onChange={(data) => setForms({ ...forms, model_id: Number(data) })}
            options={card_machines_models.map(item => { return {label: item.name, value: String(item.id)}})}
          />
        </div>
      </div>
      <div>
        <div>
          <span>Nome</span>
          <InputText 
            kind="secondary"
            placeholder="Digite o nome da máquina"
            name="name"
            value={forms.name}
            onChange={handleForms}
          />
        </div>
      </div>
    </Modal>
  )
}

export default connector(AddCardMachineModal);