import { Layout, Row } from 'antd'
import { UserInterface } from '../../../types/user.type'
import { connect, ConnectedProps } from 'react-redux'

import Dropdown from './Dropdown'

const { Header } = Layout

import './styles.css'


interface RootState {
    user: {
        info: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const CustomHeader = ({
    user
}: PropsFromRedux) => {

    return (
        <Header className="header">
            <Row align='bottom' justify='end' style={{height: "100%", paddingBottom: 22}}>
                <Row align='middle'>
                    <span className='label'>Olá, {user.name}</span>
                    <Dropdown user={user.name}/>
                </Row>
            </Row>
        </Header>
    )
}

export default connector(CustomHeader);