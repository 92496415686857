import API from '../factory/api';
import { PixInfoInterface, PixReceiptInterface, PixBS2Interface } from '../types/pix_info.type';

const getPixKeyInfo = async (pix_code: string, pix_type: string): Promise<PixInfoInterface> => {
  try {
    const jsonData = { pix_code: pix_code, pix_type: pix_type }
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };

    const { data } = await API().post(`/atar/pix/key_details`, jsonData, config);
    return data as PixInfoInterface;
  } catch (error) {
    throw error;
  }
};

const getBS2PixKeyInfo = async (pix_code: string, pix_type: string): Promise<PixInfoInterface> => {
  try {
    const jsonData = { pix_code: pix_code, pix_type: pix_type};
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };

    const { data } = await API().post(`/bs2/pix/get_pix_key_info`, jsonData, config);
    return data as PixInfoInterface;
  } catch (error) {
    throw error;
  }
};

const beginPixPayment = async (withdraw_id: number): Promise<void> => {
  try {
    await API().post(`/bs2/pix/begin_payment/${withdraw_id}`);
  } catch (error) {
    throw error;
  }
};

const getPixReceipt = async (withdraw_id: number): Promise<PixReceiptInterface> => {
  try {
    const jsonData = `{"withdraw_id": "${withdraw_id}"}`;
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    const { data } = await API().post(`/atar/pix/receipt`, jsonData, config);
    return data as PixReceiptInterface;
  } catch (error) {
    throw error;
  }
};

const getPixWithdrawalStatus = async (withdraw_id: number): Promise<string> => {
  try {
    const { data } = await API().post(`/bs2/pix/status_by_withdrawal_id/${withdraw_id}`);
    return data as string;
  } catch (error) {
    throw error;
  }
};

const getBS2PixDetails = async (withdraw_id: number): Promise<PixBS2Interface> => {
  try {
    const { data } = await API().post(`/bs2/pix/details_by_withdrawal_id/${withdraw_id}`);
    return data as PixBS2Interface;
  } catch (error) {
    throw error;
  }
};

const getPixCode = async (company_id: number) => {
  try {
    const { data } = await API().post(`/bs2/pix_deposit/get_qr_code/${company_id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export const pixService = {
  beginPixPayment,
  getPixKeyInfo,
  getPixReceipt,
  getPixWithdrawalStatus,
  getBS2PixDetails,
  getBS2PixKeyInfo,
  getPixCode
};
