import { AnyAction } from 'redux';
import { cardMachinesConstants } from '../constants/card_machine.constants';

const card_machines = (state = { machines: [], models: [], dirt: false }, action: AnyAction) => {
  switch (action.type) {
    case cardMachinesConstants.LOAD_CARD_MACHINES:
      return { ...state, machines: action.card_machines, dirt: false };
    case cardMachinesConstants.LOAD_MODELS:
      return { ...state, models: action.card_machine_models, dirt: false };
    case cardMachinesConstants.CREATE_CARD_MACHINE:
    case cardMachinesConstants.CREATE_CARD_MACHINE_MODEL:
    case cardMachinesConstants.DELETE_CARD_MACHINE:
    case cardMachinesConstants.DELETE_CARD_MACHINE_MODEL:
      return { ...state, dirt: true };
    default:
      return state;
  }
};

export default card_machines;
