import { ConnectedProps, connect } from "react-redux";

import { companiesActions } from "../../../actions/companies";
import PageHeader from "../../../components/Layout/PageHeader";

import { CertificatesInterfaceFilteredPaged } from "../../../types/withdraw.type";
import { UserInterface } from "../../../types/user.type";
import { useEffect, useState } from "react";
import PaymentVoucherTable from "../../../components/PaymentsVoucherTable";

import './styles.css'
import {ReactComponent as SearchIcon} from '../../../static/icon/search.svg';
import { CloseOutlined } from '@ant-design/icons';
import { CompaniesService } from "../../../services/company.service";
import DateRangePicker from "../../../components/DataSelector";
import { Button, Input } from "antd";


interface RootState {
    balance_history: {
        certificates: CertificatesInterfaceFilteredPaged;
    };
    
    authentication: {
        user: UserInterface;
    }
}

interface CertificatesHeaderInterface {
    total_payments: number;
    total_payments_value: number;
}
const mapState = (state: RootState) => ({
    certificates: state.balance_history.certificates,
    user: state.authentication.user,
});

const actionCreators = {
    listPixCertificatesPaged: companiesActions.listPixCertificatesPaged,
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentVoucher = ({ 
    user,
    //certificates
}: PropsFromRedux) => {

    //const storage = localStorage.getItem("userDetails")
    //const userDetails:UserInterface = storage && JSON.parse(storage)
    //const operationsName = userDetails.limits?.map(item => item.operation_type_name)
    const [filterOperation, setFilterOperation] = useState<string>("boleto");
    const [textFilter, setTextFilter] = useState<string>();
    const [textFilterType, setTextFilterType] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [certificatesData, setCertificatesData] = useState<CertificatesInterfaceFilteredPaged>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const [certificatesHeader, setCertificatesHeader] = useState<CertificatesHeaderInterface>(
        {
            total_payments: 0,
            total_payments_value: 0
        }
    );
    const changePage = (page: number) => {
        setCurrentPage(page);
    }

    const handleApplyFilter = async () => {
        if (user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id;
            const fetchedCertificates = await CompaniesService.listCertificatesByFilterPaged(company_id, textFilter, startDate, endDate, filterOperation, currentPage);
            if (fetchedCertificates) {
                setCertificatesData(fetchedCertificates);
            }
        }
        //setTextFilterType(true);
        
    }

    const getCertificatesHeader = async () => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id;
            const fetchedCertificatesHeader = await CompaniesService.getCertificatesHeader(company_id, textFilter, startDate, endDate, filterOperation);
            if (fetchedCertificatesHeader) {
                setCertificatesHeader(fetchedCertificatesHeader);
            }
        }
    }

    const printMoneyValue = (value: number) => {
        return value.toLocaleString("pt-br", {style: "currency", currency: "BRL"})
    }

    useEffect(() => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id;
            const fetchCertificates = async () => {
                const fetchedCertificates = await CompaniesService.listCertificatesByFilterPaged(company_id, textFilter, startDate, endDate, filterOperation, currentPage);
                if (fetchedCertificates) {
                    console.log(fetchedCertificates)
                    console.log(fetchedCertificates.data)
                    console.log(startDate)
                    console.log(endDate)
                    setCertificatesData(fetchedCertificates);
                    getCertificatesHeader();
                }
            };
            fetchCertificates();
        }
    }, [currentPage, filterOperation, textFilter, startDate, endDate]);

    useEffect(() => {
        if(user && user.companies && user.companies.length > 0) {
            if (textFilter?.trim() === ""){
                handleApplyFilter();
                getCertificatesHeader();
            }
        }
    }, [textFilter,]);
    
    return (
        <>
            <PageHeader title="Comprovantes"/>
            <div className='home-card-group'>
                <div className='home-card-item'>
                    <div className='home-card-describe-content'>
                    {filterOperation === 'pix' &&
                        <span className='home-card-title'>Transferencias PIX realizadas</span>
                    }
                    {filterOperation === 'boleto' &&
                        <span className='home-card-title'>Pagamentos de boletos aprovados</span>
                    }
                    <span className='home-card-describe'>{
                       certificatesHeader ? printMoneyValue(Number(certificatesHeader.total_payments_value)) : printMoneyValue(0)}
                    </span>
                    
                    {filterOperation === 'pix' &&
                        <span>
                            {Number(certificatesHeader.total_payments)  } pix realizados
                        </span>
                    }
                    {filterOperation === 'boleto' &&
                        <span>
                            {Number(certificatesHeader.total_payments)  } boletos pagos
                        </span>
                    }
                    
                    </div>
                </div>
            </div>
            <div className="voucher-container">
                <div className="voucher-filter-content"> 
                    <div className="voucher-button-div">
                        {
                            <button 
                                className={`voucher-filter-button ${filterOperation === "boleto"}`} 
                                onClick={() => {
                                    setFilterOperation("boleto");
                                }}>
                                Boleto
                            </button>
                        }
                        {
                            <button 
                                className={`voucher-filter-button ${filterOperation === "pix"}`} 
                                onClick={() => {
                                    setFilterOperation("pix");
                                }}>
                                PIX
                            </button>
                        }
                    </div>
                    <div className="value-filter-content">
                        <div>
                            <Input 
                                placeholder="Pix, boleto, beneficiario e descrição"
                                value={textFilter}
                                disabled={textFilterType}
                                className="custom-input"
                                style={{
                                    background: 'transparent', 
                                    borderBottom: '2px solid #00A19B',
                                    maxWidth: '400px',
                                    height: '50px'
                                }}
                                prefix={<SearchIcon />}
                                suffix={textFilter === "" ? !textFilter : (
                                    <Button 
                                        shape="circle" 
                                        size="large" 
                                        icon={<CloseOutlined />}
                                        className="button-input"
                                        onClick={() => {
                                            setTextFilterType(false);
                                            setTextFilter("");     
                                        }}
                                    />
                                )}
                                onChange={(event) => {
                                    //if (!textFilter)
                                        const value = event.target.value
                                        //const sanitizedValue = value.replace(/[\/\s]/g, "");
                                        setTextFilter(value);
                                    }
                                }
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleApplyFilter();
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <DateRangePicker 
                                initialStartDate={startDate}
                                initialEndDate={endDate}
                                onStartDateChange={setStartDate}
                                onEndDateChange={setEndDate}
                            />
                        </div>
                        <Button 
                            size="large"
                            title="Filtrar"
                            style={{
                                borderRadius: '5px',
                                height: '50px',
                                backgroundColor: '#00A19B',
                                color: '#fff'
                            }}
                            onClick={() => {
                                    handleApplyFilter();
                                    getCertificatesHeader();
                                }
                            }
                        >
                            Filtrar
                        </Button>
                    </div>
                    
                </div>
                <div>
                    {certificatesData &&
                        <PaymentVoucherTable 
                            balance_history={certificatesData.data}
                            pagination={certificatesData.pagination}
                            changePage={changePage}
                            currentPage={currentPage}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default connector(PaymentVoucher);