import React, { useEffect, useState } from "react";
import { Avatar, Button, message } from "antd";
import { connect, ConnectedProps } from "react-redux";

import PageHeader from "../../../../components/Layout/PageHeader";
import InputText from '../../../../components/Inputs/Text';
import CustomButton from '../../../../components/Button';
import InputPassword from "../../../../components/Inputs/Password";

import { EditFilled, CloseOutlined } from '@ant-design/icons';

import "./styles.css"
import { userActions } from "../../../../actions/user";
import { UserInterface } from "../../../../types/user.type";

interface RootState {
    user: {
        info: UserInterface;
    };
}

const mapState = (state: RootState) => ({
    user: state.user.info,
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const MyProfile = ({
    user
}: PropsFromRedux) => {

    interface formsError {
        name: {error: boolean; message: string};
        cpf: {error: boolean; message: string};
        email: {error: boolean; message: string};
        telephone: {error: boolean; message: string};
        password: {error: boolean; message: string};
        checkPassword: {error: boolean; message: string};
        ted: {error: boolean; message: string};
        pix: {error: boolean; message: string};
        boleto: {error: boolean; message: string};
    }

    const regex = {
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
    }
    const [inputActive, setInputActive] = useState<string[]>([])
    const [updateProfle, setUpdateProfile] = useState<boolean>(false)
    const [forms, setForms] = useState<UserInterface>({
        id: -1,
        name: '',
        cpf: '',
        telephone: '',
    })
    const [formsPassword, setFormsPassrowd] = useState<{password: string; checkPassword: string;}>({
        password: '',
        checkPassword: ''
    })

    const [formsError, setError] = useState<formsError>({
        name: {error: false, message: ""},
        cpf: {error: false, message: ""},
        email: {error: false, message: ""},
        telephone: {error: false, message: ""},
        password: {error: false, message: ""},
        checkPassword: {error: false, message: ""},
        ted: {error: false, message: ""},
        pix: {error: false, message: ""},
        boleto: {error: false, message: ""},
    })

    useEffect(() => {
        setForms({
            id: user.id,
            name: user.name,
            cpf: user.cpf,
            telephone: user.telephone
        })
    }, [user])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setError({ ...formsError, [name]: {error: false, message: ""} })

        if (name === "cpf") {
            setForms({
                ...forms,
                [name as string]: value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
            })
        } else if (name === "telephone") {
            setForms({
                ...forms,
                [name as string]: value.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3")
            })
        } else if (name === "password") {
            setFormsPassrowd({ ...formsPassword, [name]: value });
        } else if (name === "checkPassword") {
            setFormsPassrowd({ ...formsPassword, [name]: value });
        } else {
            setForms({ ...forms, [name]: value });
        }
    };

    const handleSetInputActive = (input:string) => {
        if (!inputActive.includes(input)) {
            setInputActive([...inputActive, input])
            setUpdateProfile(true)
        } else {
            const newInputActive = inputActive.filter(item => item !== input)
            setInputActive(newInputActive)

            if (newInputActive.length === 0) {
                setUpdateProfile(false)
            }
        }
    }

    const submitProfileUpdate = async() => {
        const keys = Object.keys(forms)
        const emptyKeys = keys.filter(key => !["password", "checkPassword"].includes(key) &&  forms[key as keyof UserInterface] === "")

        if (emptyKeys.length > 0) {
            const key = emptyKeys[0]
            setError({
                ...formsError,
                [key]: {error: true, message: "Este campo não pode ser vazio"}
            })
        } else {
            if(updateProfle) {
                const response = await userActions.updateUserInformation(forms)
                if (response) {
                    message.success("Perfil atualizado com sucesso.")
                    setTimeout(() => window.location.reload(), 3000)
                }
            }
        }   
    }

    const submitPasswordUpdate = async() => {
        const keys = Object.keys(formsPassword)
        const emptyKeys = keys.filter(key => formsPassword[key as keyof {password?: string; checkPassword?: string;}] === "")

        if (emptyKeys.length > 0) {
            const key = emptyKeys[0]
            setError({
                ...formsError,
                [key]: {error: true, message: "Este campo não pode ser vazio"}
            })
        } 
        else if (formsPassword.password && !regex.password.test(formsPassword.password)) {
            setError({
                ...formsError,
                password: {error: true, message: "Senha deve conter 8 dígitos, com um carácter maiúsculo, um carácter minúsculo e um carácter especial"}
            })
        } else if (formsPassword.checkPassword !== formsPassword.password) {
            setError({
                ...formsError,
                checkPassword: {error: true, message: "As senhas devem ser iguais"}
            })
        } else {
            const response = await userActions.updatePassword(formsPassword.password)
            if (response) {
                message.success("Senha atualizado com sucesso.")
                setTimeout(() => window.location.reload(), 3000)
            }
        }   
    }

    console.log(user)
    
    return (
        <>
            <PageHeader 
                goTo={user.permissions?.control_limits ? "/perfil" : undefined}
                //goTo={user.role === "admin" ? undefined : "/perfil"}
                title="Meus Dados"
            />
            <div className="container-my-profile">
                <div className="content-my-profile">
                    <div className="avatar-my-prfile">
                        <Avatar className="avatar-my-prfile-style">{forms.name ? forms.name[0].substring(0, 1).toUpperCase() : "T"}</Avatar>
                        <span className="profile-name">{forms?.name}</span>
                    </div>
                    <div className="forms-profile">
                        <div className="">
                            <InputText 
                                editabled
                                kind="primary"
                                label="Nome"
                                name="name"
                                value={forms.name}
                                onChange={handleChange}
                                validate={formsError.name.error ? "error" : ""}
                                errorMessage={formsError.name.message}
                                disabled={!inputActive.includes("name")}
                                suffix={
                                    <Button 
                                        shape="circle" 
                                        size="large" 
                                        icon={!inputActive.includes("name") ? <EditFilled /> : <CloseOutlined /> }
                                        onClick={() => handleSetInputActive("name")}
                                        className="button-input"
                                    />
                                }
                            />
                            
                        </div>
                        <div className="input-group-profile">
                            <InputText 
                                editabled  
                                kind="primary"
                                label="CPF"
                                name="cpf"
                                value={forms.cpf}
                                onChange={handleChange}
                                validate={formsError.cpf.error ? "error" : ""}
                                errorMessage={formsError.cpf.message}
                                disabled={!inputActive.includes("cpf")}
                                suffix={
                                    <Button 
                                        shape="circle" 
                                        size="large" 
                                        icon={!inputActive.includes("cpf") ? <EditFilled /> : <CloseOutlined /> }
                                        onClick={() => handleSetInputActive("cpf")}
                                        className="button-input"
                                    />
                                }
                            />
                            <InputText 
                                editabled
                                kind="primary"
                                label="Telefone"
                                name="telephone"
                                value={forms.telephone}
                                onChange={handleChange}
                                validate={formsError.telephone.error ? "error" : ""}
                                errorMessage={formsError.telephone.message}
                                disabled={!inputActive.includes("telephone")}
                                suffix={
                                    <Button 
                                        shape="circle" 
                                        size="large" 
                                        icon={!inputActive.includes("telephone") ? <EditFilled /> : <CloseOutlined /> }
                                        onClick={() => handleSetInputActive("telephone")}
                                        className="button-input"
                                    />
                                }
                            />
                        </div>
                        {updateProfle &&
                            <div className="forms-profile" style={{marginTop: 35, display: "flex", justifyContent: "center"}}>
                                <CustomButton 
                                    label="Alterar dados de perfil"
                                    onClick={submitProfileUpdate}
                                    style={{width: "35%"}}
                                />
                            </div>
                        }
                    </div>
                    <div className="forms-profile">
                        <span className="profile-title">Alterar Senha</span>
                        <div className="input-group-profile">
                            <InputPassword 
                                kind="primary"
                                label="Senha"
                                name="password"
                                value={formsPassword.password}
                                onChange={handleChange}
                                validate={formsError.password.error ? "error" : ""}
                                errorMessage={formsError.password.message}
                            />
                            <InputPassword 
                                kind="primary"
                                name="checkPassword"
                                label="Confirmar senha"
                                value={formsPassword.checkPassword}
                                onChange={handleChange}
                                validate={formsError.checkPassword.error ? "error" : ""}
                                errorMessage={formsError.checkPassword.message}
                            />
                        </div>
                    </div>
                    <div className="forms-profile" style={{marginTop: 35, display: "flex", justifyContent: "center"}}>
                        <CustomButton 
                            label="Redefinir senha"
                            onClick={submitPasswordUpdate}
                            style={{width: "35%"}}
                        />
                    </div>
                </div>
            </div>
        </>
        
    )
}
export default connector(MyProfile);