import { Action, Dispatch } from 'redux';
import { clientConstants } from '../constants/client.constants';
import { clientsService } from '../services/clients.service';
import { ClientInterface } from '../types/client.type';

const loadClients = () => {
  const success = (clients: ClientInterface[]) => {
    return { type: clientConstants.LOAD_CLIENTS, clients };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await clientsService.getClients();
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

const createClient = (client: ClientInterface) => {
  const success = (client: ClientInterface) => {
    return { type: clientConstants.CREATE_CLIENT, client };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      await clientsService.createClient(client);
      dispatch(success(client));
    } catch (error) {
      throw error;
    }
  };
};

const getClient = (user_id: number) => {
  const success = (client: ClientInterface) => {
    return { type: clientConstants.GET_CLIENT, client };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = await clientsService.getClient(user_id);
      dispatch(success(data));
    } catch (error) {
      throw error;
    }
  };
};

export const clientActions = {
  loadClients,
  createClient,
  getClient,
};
