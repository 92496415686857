import React from 'react';

import { Layout } from 'antd';

const { Footer } = Layout

import './styles.css'

const CustomFooter = () => {
    return (
        <Footer className="footer">Tamborete ©2021</Footer>
    )
}
export default CustomFooter;