import { Action, Dispatch } from 'redux';
import { linkPaymentConstants } from '../../constants/link_payments.constants';
import { paymentslinkService } from '../../services/payment_link.service';
import { PaymentsLink } from '../../types/payments_link';
import { TransationCard } from '../../types/transation_card';
const loadLinks = (companyId: number) => {
  const success = (links: PaymentsLink[]) => {
    return { type: linkPaymentConstants.LOAD_LINK_PAYMENTS, links };
  };

  return async (dispatch: Dispatch<Action>) => {
    try {
      const links = await paymentslinkService.getLinks(companyId);
      dispatch(success(links));
    } catch (error) {
      throw error;
    }
  };
};

const createLink = (dataLink: PaymentsLink, companyId: number) => {
  console.log(dataLink)
  return async () => {
    try {
      return (await paymentslinkService.createLink(dataLink, companyId));
    } catch (error) {
      return ""
    }
  };
};

const getLink = (linkId: string) => {
  return async () => {
    try {
      return (await paymentslinkService.getLink(linkId));
    } catch (error) {
      return ""
    }
  };
};
const transation = (transation: TransationCard) => {
  console.log(transation)
  return async () => {
    try {
      await paymentslinkService.transation(transation);
      return true
    } catch (error) {
      debugger
      return false
    }
  };
};

export const paymentsLinkActions = {
  createLink,
  loadLinks,
  getLink,
  transation
};








//const loadWithdrawals = (is_admin: boolean) => {
//  const success = (withdrawals: WithdrawInterface[]) => {
//    return { type: withdrawConstants.LOAD_WITHDRAWALS, withdrawals };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const withdrawals = await withdrawService.getWithdrawals(is_admin);
//      dispatch(success(withdrawals));
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const loadWithdrawalsByDate = (is_admin: boolean, start_date: string, end_date: string) => {
//  const success = (withdrawals: WithdrawInterface[]) => {
//    return { type: withdrawConstants.LOAD_WITHDRAWALS, withdrawals };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const withdrawals = await withdrawService.getWithdrawalsByDate(is_admin, start_date, end_date);
//      dispatch(success(withdrawals));
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const createWithdraw = (withdraw: WithdrawInterface, type: string) => {
//  const success = () => {
//    return { type: withdrawConstants.CREATE_WITHDRAW };
//  };
//  return async (dispatch: Dispatch<Action>) => {
//    console.log(withdraw)
//    try {
//      if (type === "PIX") {
//        await withdrawService.createPixWithdraw(withdraw);
//      }
//      dispatch(success());
//    } catch (error) {
//      throw error;
//    }
//  };
//};
//
//const createClientTicketWithdraw = (withdraw: ReceiptWithdrawRequestInterface) => {
//
//  return async () => {
//    try {
//      await withdrawService.createClientTicketWithdraw(withdraw);
//      return true
//    } catch (error) {
//      return false
//    }
//  };
//};
//
//const getReceiptInfo = (receipt_id: string) => {
//  const success = (clients: ReceiptInterface) => {
//    return { type: clientConstants.RECEIPT_INFO, clients };
//  };
//
//  return async (dispatch: Dispatch<Action>) => {
//    try {
//      const data = await withdrawService.getReceiptInfo(receipt_id);
//      dispatch(success(data));
//    } catch (error) {
//      throw error;
//    }
//  };
//};