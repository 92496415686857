import { AnyAction } from 'redux';
import { companiesConstants } from '../constants/companies.constants';

const companies = (state = [], action: AnyAction) => {
    switch (action.type) {
        case companiesConstants.LIST_COMPANIES:
            return action.companies
        default:
            return state;
    }
}

export default companies